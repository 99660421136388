export interface BpmnViewerBrandingDto {
        accentFill : string;
        accentStroke : string;
        primaryFill : string;
        primaryStroke : string;
}

export interface BrandingDto {
        bpmn : BpmnViewerBrandingDto;
        backgroundColor : string;
        foregroundColor : string;
        primaryColor : string;
        accentColor : string;
        logoUrl : string;
}

export interface ErrorCode {
        code : string;
        description : string;
}

export interface Result<T> {
        value : T;
        success : boolean;
        error : ErrorCode;
}

export interface DictionaryEntryInfoDto {
        value : string;
        displayName : string;
}

export interface DictionaryInfoDto {
        items : DictionaryEntryInfoDto[];
        schemaIdentifier : string;
}

export interface Challenge {
        startedAt : string;
        innoChainObjectId : string;
        signMe : string;
}

export interface CertificateHolderPersonInfo {
        surName : string;
        givenName : string;
        email : string;
        organization : string;
        position : string;
}

export interface CertificateHolderInfo {
        person : CertificateHolderPersonInfo;
        innoChainObjectId : string;
        publicKey : string;
}

export enum Gost34102012KeyType {
        Gost256 = "Gost256",
        Gost512 = "Gost512",
}

export interface KeyInfo {
        type : Gost34102012KeyType;
        hex : string;
        pem : string;
}

export enum ParameterSelectionMode {
        Manual = "Manual",
        AutoInnoChainObjectId = "AutoInnoChainObjectId",
}

export interface StartableDiagramDto {
        id : number;
        chainId : string;
        title : string;
        transactionHash : string;
        transactionStatus : string;
        updatedAt : string;
        createdAt : string;
        jsonSchema : any;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface StartedDiagramToken {
        tokenId : number | null;
        requestId : number | null;
        activityId : string;
}

export interface StartedDiagramFileNameDto {
        id : string;
        fileName : string;
}

export interface StartedDiagramDto {
        id : string;
        title : string;
        createdAt : string;
        updatedAt : string;
        status : string;
        hash : string;
        diagramStatus : string;
        diagramStatusColor : string;
        diagramTokens : StartedDiagramToken[];
        fileNames : StartedDiagramFileNameDto[];
}

export interface InnoFormFullDto {
        jsonSchema : string;
        json : string;
        activityId : string;
        requestId : string;
        tokenId : number;
        status : string;
        hash : string;
        createdAt : string;
        publishedAt : string | null;
        innoChainObjectId : string;
        contractAccountId : string;
        isPublished : boolean;
        isFilled : boolean;
        title : string;
        id : number;
}

export interface StartedDiagramFullDto {
        diagramXml : string;
        id : string;
        title : string;
        createdAt : string;
        updatedAt : string;
        status : string;
        hash : string;
        diagramStatus : string;
        diagramStatusColor : string;
        diagramTokens : StartedDiagramToken[];
        fileNames : StartedDiagramFileNameDto[];
}

export interface StartedDiagramFileDto {
        fileContent : string;
        id : string;
        fileName : string;
}

export interface InnoFormBriefDto {
        activityId : string;
        requestId : string;
        tokenId : number;
        status : string;
        hash : string;
        createdAt : string;
        publishedAt : string | null;
        innoChainObjectId : string;
        contractAccountId : string;
        isPublished : boolean;
        isFilled : boolean;
        title : string;
        id : number;
}

export interface ValidationErrorMessage {
        kind : string;
        propertyPath : string;
}


export interface ICoreRpcProxyForAnonBrandingRpc {
    getBranding () : Promise<BrandingDto>;
}
export class CoreRpcProxyForAnonBrandingRpc implements ICoreRpcProxyForAnonBrandingRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getBranding () : Promise<BrandingDto>    {
        return this.parent.send<BrandingDto>({Target: 'AnonBrandingRpc', MethodSignature: 'C0dldEJyYW5kaW5n', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAnonDictionaryRpc {
    getDictionary (schemaIdentifier : string) : Promise<Result<DictionaryInfoDto>>;
}
export class CoreRpcProxyForAnonDictionaryRpc implements ICoreRpcProxyForAnonDictionaryRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getDictionary (schemaIdentifier : string) : Promise<Result<DictionaryInfoDto>>    {
        return this.parent.send<Result<DictionaryInfoDto>>({Target: 'AnonDictionaryRpc', MethodSignature: 'DUdldERpY3Rpb25hcnkNU3lzdGVtLlN0cmluZw==', Arguments: [schemaIdentifier] });
    }
}
export interface ICoreRpcProxyForUserLoginRpc {
    startChallenge (innoChainObjectId : string, publicKey : string) : Promise<Result<Challenge>>;
    completeChallenge (innoChainObjectId : string, signature : string) : Promise<Result<string>>;
    getHolderInfoFromCertificate (pem : string) : Promise<Result<CertificateHolderInfo>>;
    convertHexKeyToPem (hexKey : string) : Promise<Result<KeyInfo>>;
    isAuthorized (token : string) : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public startChallenge (innoChainObjectId : string, publicKey : string) : Promise<Result<Challenge>>    {
        return this.parent.send<Result<Challenge>>({Target: 'UserLoginRpc', MethodSignature: 'DlN0YXJ0Q2hhbGxlbmdlDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [innoChainObjectId,publicKey] });
    }
    public completeChallenge (innoChainObjectId : string, signature : string) : Promise<Result<string>>    {
        return this.parent.send<Result<string>>({Target: 'UserLoginRpc', MethodSignature: 'EUNvbXBsZXRlQ2hhbGxlbmdlDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [innoChainObjectId,signature] });
    }
    public getHolderInfoFromCertificate (pem : string) : Promise<Result<CertificateHolderInfo>>    {
        return this.parent.send<Result<CertificateHolderInfo>>({Target: 'UserLoginRpc', MethodSignature: 'HEdldEhvbGRlckluZm9Gcm9tQ2VydGlmaWNhdGUNU3lzdGVtLlN0cmluZw==', Arguments: [pem] });
    }
    public convertHexKeyToPem (hexKey : string) : Promise<Result<KeyInfo>>    {
        return this.parent.send<Result<KeyInfo>>({Target: 'UserLoginRpc', MethodSignature: 'EkNvbnZlcnRIZXhLZXlUb1BlbQ1TeXN0ZW0uU3RyaW5n', Arguments: [hexKey] });
    }
    public isAuthorized (token : string) : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DElzQXV0aG9yaXplZA1TeXN0ZW0uU3RyaW5n', Arguments: [token] });
    }
}
export interface ICoreRpcProxyForUserRpc {
    getStartableDiagramSelectionMode () : Promise<ParameterSelectionMode>;
    getStartableDiagram () : Promise<StartableDiagramDto>;
    getStartableDiagrams (search : string, committedOnly : boolean, skip : number, take : number) : Promise<ListResult<StartableDiagramDto>>;
    getStartedDiagrams (skip : number, take : number) : Promise<ListResult<StartedDiagramDto>>;
    getById (id : number) : Promise<InnoFormFullDto>;
    getStartedDiagramById (contractAccountId : string) : Promise<StartedDiagramFullDto>;
    getStartedDiagramFileById (contractAccountId : string, fileId : string) : Promise<StartedDiagramFileDto>;
    getAll (skip : number, take : number, published : boolean) : Promise<ListResult<InnoFormBriefDto>>;
    getPublished (skip : number, take : number) : Promise<ListResult<InnoFormBriefDto>>;
    saveForm (id : number, json : string) : Promise<ValidationErrorMessage[]>;
    publishForm (id : number, payload : string) : Promise<Result<boolean>>;
    publishDiagram (id : number, json : string, payload : string) : Promise<ValidationErrorMessage[]>;
    uploadContract (alias : string, payload : string) : Promise<Result<boolean>>;
}
export class CoreRpcProxyForUserRpc implements ICoreRpcProxyForUserRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getStartableDiagramSelectionMode () : Promise<ParameterSelectionMode>    {
        return this.parent.send<ParameterSelectionMode>({Target: 'UserRpc', MethodSignature: 'IEdldFN0YXJ0YWJsZURpYWdyYW1TZWxlY3Rpb25Nb2Rl', Arguments: [] });
    }
    public getStartableDiagram () : Promise<StartableDiagramDto>    {
        return this.parent.send<StartableDiagramDto>({Target: 'UserRpc', MethodSignature: 'E0dldFN0YXJ0YWJsZURpYWdyYW0=', Arguments: [] });
    }
    public getStartableDiagrams (search : string, committedOnly : boolean, skip : number, take : number) : Promise<ListResult<StartableDiagramDto>>    {
        return this.parent.send<ListResult<StartableDiagramDto>>({Target: 'UserRpc', MethodSignature: 'FEdldFN0YXJ0YWJsZURpYWdyYW1zDVN5c3RlbS5TdHJpbmcOU3lzdGVtLkJvb2xlYW4MU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMg==', Arguments: [search,committedOnly,skip,take] });
    }
    public getStartedDiagrams (skip : number, take : number) : Promise<ListResult<StartedDiagramDto>>    {
        return this.parent.send<ListResult<StartedDiagramDto>>({Target: 'UserRpc', MethodSignature: 'EkdldFN0YXJ0ZWREaWFncmFtcwxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public getById (id : number) : Promise<InnoFormFullDto>    {
        return this.parent.send<InnoFormFullDto>({Target: 'UserRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDY0', Arguments: [id] });
    }
    public getStartedDiagramById (contractAccountId : string) : Promise<StartedDiagramFullDto>    {
        return this.parent.send<StartedDiagramFullDto>({Target: 'UserRpc', MethodSignature: 'FUdldFN0YXJ0ZWREaWFncmFtQnlJZA1TeXN0ZW0uU3RyaW5n', Arguments: [contractAccountId] });
    }
    public getStartedDiagramFileById (contractAccountId : string, fileId : string) : Promise<StartedDiagramFileDto>    {
        return this.parent.send<StartedDiagramFileDto>({Target: 'UserRpc', MethodSignature: 'GUdldFN0YXJ0ZWREaWFncmFtRmlsZUJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [contractAccountId,fileId] });
    }
    public getAll (skip : number, take : number, published : boolean) : Promise<ListResult<InnoFormBriefDto>>    {
        return this.parent.send<ListResult<InnoFormBriefDto>>({Target: 'UserRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDlN5c3RlbS5Cb29sZWFu', Arguments: [skip,take,published] });
    }
    public getPublished (skip : number, take : number) : Promise<ListResult<InnoFormBriefDto>>    {
        return this.parent.send<ListResult<InnoFormBriefDto>>({Target: 'UserRpc', MethodSignature: 'DEdldFB1Ymxpc2hlZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public saveForm (id : number, json : string) : Promise<ValidationErrorMessage[]>    {
        return this.parent.send<ValidationErrorMessage[]>({Target: 'UserRpc', MethodSignature: 'CFNhdmVGb3JtDFN5c3RlbS5JbnQ2NA1TeXN0ZW0uU3RyaW5n', Arguments: [id,json] });
    }
    public publishForm (id : number, payload : string) : Promise<Result<boolean>>    {
        return this.parent.send<Result<boolean>>({Target: 'UserRpc', MethodSignature: 'C1B1Ymxpc2hGb3JtDFN5c3RlbS5JbnQzMg1TeXN0ZW0uQnl0ZVtd', Arguments: [id,payload] });
    }
    public publishDiagram (id : number, json : string, payload : string) : Promise<ValidationErrorMessage[]>    {
        return this.parent.send<ValidationErrorMessage[]>({Target: 'UserRpc', MethodSignature: 'DlB1Ymxpc2hEaWFncmFtDFN5c3RlbS5JbnQzMg1TeXN0ZW0uU3RyaW5nDVN5c3RlbS5CeXRlW10=', Arguments: [id,json,payload] });
    }
    public uploadContract (alias : string, payload : string) : Promise<Result<boolean>>    {
        return this.parent.send<Result<boolean>>({Target: 'UserRpc', MethodSignature: 'DlVwbG9hZENvbnRyYWN0DVN5c3RlbS5TdHJpbmcNU3lzdGVtLkJ5dGVbXQ==', Arguments: [alias,payload] });
    }
}
export interface ICoreApi {
    anonBranding : ICoreRpcProxyForAnonBrandingRpc;
    anonDictionary : ICoreRpcProxyForAnonDictionaryRpc;
    userLogin : ICoreRpcProxyForUserLoginRpc;
    user : ICoreRpcProxyForUserRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.anonBranding = new CoreRpcProxyForAnonBrandingRpc(this);
    this.anonDictionary = new CoreRpcProxyForAnonDictionaryRpc(this);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.user = new CoreRpcProxyForUserRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    anonBranding : CoreRpcProxyForAnonBrandingRpc;
    anonDictionary : CoreRpcProxyForAnonDictionaryRpc;
    userLogin : CoreRpcProxyForUserLoginRpc;
    user : CoreRpcProxyForUserRpc;
}
