import * as React from "react";
import { BpmnViewerBrandingDto, StartedDiagramFullDto } from "src/api";
import BpmnModeler from "bpmn-js/dist/bpmn-modeler.production.min.js";

type ReactBpmnEditorProps = {
    diagram: StartedDiagramFullDto;
    branding: BpmnViewerBrandingDto;
};

export class ReactBpmnEditor extends React.Component<ReactBpmnEditorProps, {}> {
    private readonly containerRef: React.RefObject<HTMLDivElement>;
    private bpmnViewer: typeof BpmnModeler;

    constructor(props: any) {
        super(props);
        this.containerRef = React.createRef<HTMLDivElement>();
    }

    async componentDidMount(): Promise<void> {
        const container = this.containerRef.current;
        this.bpmnViewer = new BpmnModeler({ container });
    }

    async componentDidUpdate(): Promise<void> {
        const viewer = this.bpmnViewer;
        await viewer.importXML(this.props.diagram.diagramXml);
        viewer.get("canvas").zoom("fit-viewport", "auto");

        // Get basic BPMN viewer components.
        try {
            console.log("Trying to get modeling");
            const modeling = viewer.get("modeling");
            console.log("Trying to get elementRegistry");
            const elementRegistry = viewer.get("elementRegistry");
            this.props.diagram.diagramTokens.forEach((token) => {
                const activity = token.activityId;
                console.log(`Trying to get ${activity}`);
                const event = elementRegistry.get(activity);
                console.log(`Trying to set color of ${activity}`);
                if (event) {
                    modeling.setColor([event], {
                        stroke: this.props.branding.accentStroke,
                        fill: this.props.branding.accentFill,
                    });
                } else {
                    console.warn(`No element ${activity} has been found.`);
                }
            });
        } catch (e) {
            console.warn("Fatal error occured while interacting with bpmn viewer!");
        }
    }

    componentWillUnmount() {
        this.bpmnViewer.destroy();
    }

    render() {
        return <div ref={this.containerRef} style={{ marginTop: 10, pointerEvents: "none" }} />;
    }
}
