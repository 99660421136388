import React, { FC, useState } from "react";
import { shadeColor, toRussianDate, useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { Input } from "reactstrap";
import styles from "src/pages/UploadPage/upload-page.module.css";
import { BrandingStore } from "src/stores/BrandingStore";
import { UploadStore } from "src/stores/UploadStore";
import { StorePagination } from "src/components/Pagination/Pagination";
import { StartableDiagramDto } from "src/api";
import { MessageBox } from "src/components/MessageBox/MessageBox";
import { Timer } from "src/utils/timer";

type UploadPageHeaderProps = {
    branding: BrandingStore;
    store: UploadStore;
};

const UploadPageHeader: FC<UploadPageHeaderProps> = ({ branding, store }) =>
    useObserver(() => (
        <div className={styles.formHeader}>
            <button
                className={styles.button_blue}
                style={{ background: branding.branding.accentColor, opacity: 1 }}
                onClick={() => store.goBack()}
            >
                Назад
            </button>
            <div className={styles.formHeaderActions}>
                <button
                    className={styles.button_blue}
                    style={{ background: branding.branding.accentColor }}
                    onClick={() => store.toggleUploaded(false)}
                    disabled={!store.uploaded}
                >
                    Загрузка контракта
                </button>
                <button
                    className={styles.button_blue}
                    style={{ background: branding.branding.primaryColor }}
                    onClick={() => store.toggleUploaded(true)}
                    disabled={store.uploaded}
                >
                    Загруженные контракты
                </button>
            </div>
            <MessageBox
                header={"Контракт загружен"}
                branding={branding}
                message={"Операция выполнена успешно! Вы можете закрыть данное окно."}
                isOpen={store.isSuccessModalOpen}
                onDiscard={() => (store.isSuccessModalOpen = false)}
            />
        </div>
    ));

type NewContractUploadProps = {
    branding: BrandingStore;
    store: UploadStore;
};

const NewContractUpload: FC<NewContractUploadProps> = ({ store, branding }) =>
    useObserver(() => (
        <div className={styles.formTable__container}>
            <h2 className={styles.formTable__title}>Здесь Вы можете загрузить ZIP-архив контракта</h2>
            <div className={styles.formTable__content}>
                <div>
                    <label>Псевдоним контракта</label>
                    <Input
                        type={"text"}
                        disabled={store.isBusy}
                        value={store.alias}
                        onChange={(e) => (store.alias = e.target.value)}
                        placeholder={"Псевдоним контракта"}
                    />
                </div>
                <div style={{ marginTop: 10 }}>
                    <Input
                        type={"file"}
                        style={{ width: "100%" }}
                        placeholder={"ZIP-архив контракта"}
                        disabled={store.isBusy}
                        accept=".zip"
                        onChange={(x) => {
                            if (!x.target.files) return;
                            const file = x.target.files.item(0);
                            if (file) store.file = file;
                        }}
                    />
                </div>
                <div style={{ marginTop: 10 }}>
                    <button
                        className={styles.buttonUpload}
                        disabled={!store.canUpload}
                        style={{ background: branding.branding.accentColor }}
                        onClick={() => store.upload()}
                    >
                        Подписать и отправить
                    </button>
                </div>
            </div>
        </div>
    ));

type ContractItemProps = {
    branding: BrandingStore;
    diagram: StartableDiagramDto;
};

const ContractItem: FC<ContractItemProps> = ({ diagram, branding }) => {
    const [hover, setHover] = useState(false);
    return useObserver(() => (
        <div
            className={styles.formTable__item}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ background: hover ? branding.branding.primaryColor : undefined }}
            tabIndex={0}
        >
            <div className={styles.formTable__itemHead} style={{ color: hover ? "white" : "inherit" }}>
                <div className={styles.formTable__itemName} style={{ color: hover ? "white" : "inherit" }}>
                    <b>{diagram.title}</b> <small style={{ opacity: 0.5 }}>{diagram.chainId}</small>
                </div>
                <div className={styles.formTable__itemDate}>{toRussianDate(diagram.createdAt)}</div>
            </div>
            {diagram.transactionHash && (
                <div
                    className={styles.formTable__itemText}
                    style={{ color: hover ? "white" : "inherit", opacity: 0.5 }}
                >
                    {diagram.transactionHash}
                </div>
            )}
            {diagram.updatedAt && (
                <div className={styles.formTable__itemText} style={{ color: hover ? "white" : "inherit" }}>
                    {`Получение подтверждено: ${toRussianDate(diagram.updatedAt)}.`}
                </div>
            )}
            {diagram.transactionStatus && (
                <div className={styles.formTable__itemBadge} style={{ backgroundColor: branding.branding.accentColor }}>
                    {diagram.transactionStatus}
                </div>
            )}
        </div>
    ));
};

type UploadedContractsTableProps = {
    branding: BrandingStore;
    store: UploadStore;
};

const UploadedContractsTable: FC<UploadedContractsTableProps> = ({ branding, store }) =>
    useObserver(() => (
        <div className={styles.formTable__container}>
            <h2 className={styles.formTable__title}>Загруженные контракты</h2>
            <div>
                {store.items.map((diagram, index) => (
                    <ContractItem key={index} diagram={diagram} branding={branding} />
                ))}
                {store.items.length === 0 && (
                    <div className={styles.formTable__nothing}>По данным критериям ничего не найдено.</div>
                )}
            </div>
            <StorePagination store={store} />
            <Timer interval={5000} onTick={() => store.refresh()} />
        </div>
    ));

export const UploadPage: FC = () => {
    const {
        rootStore: { brandingStore: branding, uploadStore: store },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <UploadPageHeader branding={branding} store={store} />
            {store.uploaded ? (
                <UploadedContractsTable store={store} branding={branding} />
            ) : (
                <NewContractUpload store={store} branding={branding} />
            )}
        </div>
    ));
};
