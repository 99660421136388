// Модуль AutoCompleteSelect является пользовательским элементом управления, позволяющим осуществлять выбор из заранее
// известного списка значений, а также поиск по заранее известному списку значений. Компонент расширяет элемент
// управления Select библиотеки react-dropdown-select.

import React, { FC, useCallback, useState } from "react";
import { useObserver } from "mobx-react";
import { AutoCompleteSelectStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";
import { DependencyList, useEffect } from "react";
import { mapChangeEventToValue } from "src/utilities";
import Select from "react-dropdown-select";

export interface UseThrottleEffectProps<T> {
    action: (data: T) => Promise<void>;
    timeout: number;
    data: T;
}

export function useThrottle<T>({ action, timeout, data }: UseThrottleEffectProps<T>, deps: DependencyList) {
    return useEffect(() => {
        let timeoutId: number | null = null;
        let active = true;
        const cleanup = () => {
            active = false;
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = 0;
            }
        };

        timeoutId = window.setTimeout(async () => {
            if (active) {
                try {
                    await action(data);
                } finally {
                    cleanup();
                }
            }
        }, timeout);
        return cleanup;
    }, deps);
}

type AutoCompleteSelectProps = {
    store: AutoCompleteSelectStore<any>;
    placeholder: string;
    throttleTimeout?: number;
    className?: string;
    disabled?: boolean;
};

export const AutoCompleteSelect: FC<AutoCompleteSelectProps> = ({
    store,
    disabled,
    placeholder,
    throttleTimeout = 300,
}) => {
    const onChange = (item: any) => (store.value = item);
    const onAddressChoose = useCallback(([x]: any[]) => onChange(x), [onChange]);
    const values = store.value ? [store.value] : [];
    const [query, setQuery] = useState("");

    useThrottle(
        {
            action: (x: string) => store.suggest(x),
            data: query,
            timeout: throttleTimeout,
        },
        [query, store]
    );

    return useObserver(() => (
        <Select
            disabled={disabled}
            values={values}
            options={store.items}
            placeholder={placeholder}
            labelField={store.labelField}
            onChange={onAddressChoose}
            clearable={true}
            searchable={true}
            searchBy={store.labelField}
            valueField={store.valueField}
            additionalProps={{ onChange: mapChangeEventToValue(setQuery) }}
        />
    ));
};
