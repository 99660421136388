import React, { FC } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { JsonSchemaForm } from "src/components/JsonSchemaForm/JsonSchemaForm";
import style from "src/pages/DeviceLoginPage/device-login.module.css";
import { ValidatedMonaco } from "src/components/Monaco/ValidatedMonaco";
import styles from "src/pages/DbgPage/dbg-page.module.css";

export const DbgPage: FC = () => {
    const {
        rootStore: { debugStore: store, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div className={styles.formTable__container}>
            <h2 className={styles.formTable__title}>Режим отладки</h2>
            <div className={styles.formTable__content}>
                <JsonSchemaForm
                    jsonSchema={store.jsonSchema}
                    formData={store.jsonForm}
                    disabled={false}
                    onFormDataChange={(formData) => store.handleFormDataChange(formData)}
                >
                    <div />
                </JsonSchemaForm>
                <div style={{ marginTop: 20 }}>
                    <ValidatedMonaco
                        maxHeight={"10vh"}
                        value={store.jsonFormRaw}
                        placeholder={"JSON Form Data"}
                        readonly={true}
                    />
                    <ValidatedMonaco
                        maxHeight={"35vh"}
                        value={store.jsonSchemaRaw}
                        placeholder={"JSON Schema"}
                        callback={(value) => (store.jsonSchemaRaw = value)}
                    />
                    <button
                        className={style.deviceLogin__buttonLogin}
                        style={{ background: branding.branding.primaryColor }}
                        onClick={() => store.update()}
                    >
                        Сгенерировать форму
                    </button>
                </div>
            </div>
        </div>
    ));
};
