import { computed, observable } from "mobx";
import { BrandingDto } from "src/api";
import { RootStore } from "src/stores/RootStore";

export class BrandingStore {
    @observable configuredBranding?: BrandingDto;

    constructor(private readonly root: RootStore) {}

    @computed get isLoaded(): boolean {
        return !!this.configuredBranding;
    }

    @computed get branding(): BrandingDto {
        if (this.configuredBranding) return this.configuredBranding;
        throw new Error("Branding hasn't been loaded!");
    }

    async load(): Promise<void> {
        this.configuredBranding = await this.root.anonRpc.anonBranding.getBranding();
    }
}
