// Модуль CertificateSelectionPage реализует страницу выбора сертификатов, установленных на устройстве РуТокен ЭЦП.
// Поддерживаются только сертификаты с указанным внешним идентификатором InnoChainObjectId, по правилам, определяемым
// на стороне сервера в компоненте CertificateHolderParser.

import { FC, useState } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import * as React from "react";
import style from "./certificate-selection.module.css";
import { CertificateSelectionStore, SelectableCertificate } from "src/stores/CertificateSelectionStore";
import icon from "src/assets/img/certificate.svg";
import { BrandingStore } from "src/stores/BrandingStore";

type CertificateItemProps = {
    certificate: SelectableCertificate;
    store: CertificateSelectionStore;
    branding: BrandingStore;
};

const CertificateItem: FC<CertificateItemProps> = ({ certificate, store, branding }) => {
    const [hover, setHover] = useState(false);
    return useObserver(() => {
        const isSelected = store.value === certificate;
        const className = isSelected ? style.certificateTable__itemSelected : "";
        return (
            <div
                className={style.certificateTable__item + " " + className}
                style={{ background: hover || isSelected ? branding.branding.primaryColor : undefined }}
                onClick={() => (store.value = certificate)}
                tabIndex={0}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <img className={style.certificateTable__img} src={icon} alt={certificate.id} />
                <div>
                    <div
                        className={style.certificateTable__itemHead}
                        style={{ color: hover || isSelected ? "white" : "inherit" }}
                    >
                        <div className={style.certificateTable__itemName}>Сертификат {certificate.id}</div>
                    </div>
                    <div
                        className={style.certificateTable__itemText}
                        style={{ color: hover || isSelected ? "white" : "inherit" }}
                    >
                        <b>{certificate.personInfo.surName ?? ""}</b>{" "}
                        <b>{certificate.personInfo.givenName ?? "Имя не указано"}</b>
                        {", "}
                        {certificate.personInfo.position ?? "должность не указана"}
                        {", "}
                        {certificate.personInfo.organization ?? "организация не указана"}
                        {", "}
                        {certificate.personInfo.email ?? "почта не указана"}
                    </div>
                </div>
            </div>
        );
    });
};

export const CertificateSelectionPage: FC = () => {
    const {
        rootStore: { certificateSelectionStore: store, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <div className={style.certificateSelection__container}>
                <div className={style.certificateSelection__containerHeading}>Выбор сертификата</div>
                <div className={style.certificateSelection__containerMessage}>
                    Пожалуйста, выберите сертификат из списка доступных сертификатов ниже.
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    {store.items.map((certificate) => (
                        <CertificateItem
                            key={certificate.id}
                            certificate={certificate}
                            store={store}
                            branding={branding}
                        />
                    ))}
                </div>
                {!!store.value && (
                    <div className={style.certificateSelection__containerMessage}>
                        Выбран сертификат с внешним идентификатором: <b>{store.value?.innoChainObjectId}</b>
                    </div>
                )}
                <button
                    className={style.certificateSelection__buttonSelect}
                    style={{ background: branding.branding.primaryColor }}
                    disabled={!store.canProceed}
                    onClick={() => store.proceed()}
                >
                    Подтвердить и продолжить
                </button>
            </div>
        </div>
    ));
};
