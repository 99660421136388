// Модуль NotFoundPage реализует страницу ошибки 404. Если маршрут, который открыл пользователь, является неизвестным,
// то произойдёт перенаправление пользователя на данную страницу. Со страницы ошибки 404 пользователь системы может
// вернуться на страницу списка форм.

import { FC } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import style from "./not-found.module.css";
import * as React from "react";
import { UserRouteNames } from "src/routes";

export const NotFoundPage: FC = () => {
    const {
        rootStore: { routerStore: store, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <div className={style.notFound__container}>
                <div className={style.notFound__containerHeading}>Здесь ничего нет!</div>
                <button
                    className={style.notFound__containerButton}
                    style={{ background: branding.branding.primaryColor }}
                    onClick={() => store.goTo(UserRouteNames.forms)}
                >
                    На главную страницу
                </button>
            </div>
        </div>
    ));
};
