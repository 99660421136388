// Модуль DeviceLoginStore реализует логику страницы авторизации на устройстве РуТокен ЭЦП по паролю. В случае, если
// устройство не обнаружено, данная страница показана не будет. PIN-код РуТокен ЭЦП по умолчанию - 12345678.

import { RootStore } from "src/stores/RootStore";
import { action, observable } from "mobx";
import { UserRouteNames } from "src/routes";
import { KnownErrorCode } from "src/stores/RuTokenApi";

export class DeviceLoginStore {
    private isActuallyLoggedIn?: boolean;

    @observable isLoggingIn: boolean = false;
    @observable password: string = "";
    @observable error: string = "";

    constructor(private readonly root: RootStore) {}

    async isLoggedInDevice(): Promise<boolean> {
        if (this.isActuallyLoggedIn !== undefined) {
            return this.isActuallyLoggedIn;
        }

        this.isActuallyLoggedIn = await this.root.ruTokenApi.isLoggedInDevice();
        return this.isActuallyLoggedIn;
    }

    @action async logInDevice(): Promise<void> {
        this.isActuallyLoggedIn = undefined;
        this.isLoggingIn = true;
        this.error = "";
        try {
            const password = this.password;
            await this.root.ruTokenApi.logInDevice(password);
            this.isActuallyLoggedIn = true;
            await this.root.routerStore.goTo(UserRouteNames.forms);
        } catch (error) {
            if (error === KnownErrorCode.pinIncorrect) {
                this.isActuallyLoggedIn = false;
                this.error = "Выполнить вход не удалось, использован некорректный PIN.";
            } else {
                this.isActuallyLoggedIn = undefined;
                this.error = "";
                await this.root.routerStore.goTo(UserRouteNames.error);
            }
        } finally {
            this.isLoggingIn = false;
        }
    }

    @action async logOutDevice(): Promise<void> {
        this.isActuallyLoggedIn = undefined;
        await this.root.routerStore.goTo(UserRouteNames.forms);
        window.location.reload();
    }
}
