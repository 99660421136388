// Модуль utilities определяет функции и утилиты, используемые различными компонентами системы.

import { MobXProviderContext } from "mobx-react";
import { RootStore } from "src/stores/RootStore";
import { ChangeEvent, useContext } from "react";

export function uniqueId(): string {
    return (Math.random() + 1).toString(36).substring(18);
}

export function shadeColor(color: string, percent: number): string {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = Math.floor(R * (100 + percent) / 100);
    G = Math.floor(G * (100 + percent) / 100);
    B = Math.floor(B * (100 + percent) / 100);
    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;
    let RR = ((R.toString(16).length==1) ? "0" + R.toString(16):R.toString(16));
    let GG = ((G.toString(16).length==1) ? "0" + G.toString(16):G.toString(16));
    let BB = ((B.toString(16).length==1) ? "0" + B.toString(16):B.toString(16));
    return "#" + RR + GG + BB;
}

export const useRootStore = () => {
    return useContext(MobXProviderContext) as { rootStore: RootStore };
};

export const toRussianDate = (raw: string) => {
    const date = new Date(raw.split(".")[0]);
    return date.toLocaleString("ru");
}

export const mapChangeEventToValue = <T extends {}>(callback: (x: string) => void) => (
    x: ChangeEvent<HTMLInputElement>
) => callback(x.target.value);

export function genUuid() {

    var crypto = window.crypto || window["msCrypto"];
    function randomDigit() {
        if (crypto && crypto.getRandomValues) {
            var rands = new Uint8Array(1);
            crypto.getRandomValues(rands);
            return (rands[0] % 16).toString(16);
        } else {
            return ((Math.random() * 16) | 0).toString(16);
        }
    }
    return 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, randomDigit);
}
