// Модуль ErrorPage реализует страницу отображения ошибки устройства РуТокен ЭЦП или информационной системы. При этом
// в случае нажатия пользователем кнопки "Попробовать снова" произойдёт перезагрузка веб-приложения, система попытается
// обнаружить устройство и выполнить действие повторно.

import { FC } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import style from "./error-page.module.css";
import * as React from "react";
import { UserRouteNames } from "src/routes";

export const ErrorPage: FC = () => {
    const {
        rootStore: { errorStore: store, brandingStore: branding, routerStore: router },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <div className={style.errorView__container}>
                <div className={style.errorView__containerHeading}>Внимание</div>
                <div className={style.errorView__containerDescription}>
                    {store.errorMessage ?? "Неизвестная ошибка"}
                </div>
                <button
                    className={style.errorView__containerButton}
                    style={{ background: branding.branding.accentColor }}
                    onClick={() => store.retry()}
                >
                    Попробовать снова
                </button>
            </div>
        </div>
    ));
};
