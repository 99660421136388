import * as React from "react";
import { FC } from "react";
import { BrandingStore } from "src/stores/BrandingStore";
import styles from "src/components/MessageBox/message-box.module.css";

export const ModalFormButtonContainer: FC = (props) => <div className={styles.modalForm__buttonContainer} {...props} />;

type ModalFormAccentButtonProps = React.ButtonHTMLAttributes<any> & {
    branding: BrandingStore;
    text: string;
};

export const ModalFormAccentButton: FC<ModalFormAccentButtonProps> = (props) => (
    <div
        className={styles.modalForm__buttonFollow}
        style={{ background: props.branding.branding.accentColor }}
        {...props}
    >
        {props.text}
    </div>
);

type ModalFormPrimaryButtonProps = React.ButtonHTMLAttributes<any> & {
    branding: BrandingStore;
    text: string;
};

export const ModalFormPrimaryButton: FC<ModalFormPrimaryButtonProps> = (props) => (
    <div
        className={styles.modalForm__buttonSave}
        style={{ background: props.branding.branding.primaryColor }}
        {...props}
    >
        {props.text}
    </div>
);

type ModalFormHeaderPrimaryButtonProps = React.ButtonHTMLAttributes<any> & {
    branding: BrandingStore;
    text: string;
};

export const ModalFormHeaderPrimaryButton: FC<ModalFormHeaderPrimaryButtonProps> = (props) => (
    <div
        className={styles.modalForm__closeButton}
        style={{ background: props.branding.branding.primaryColor }}
        {...props}
    >
        {props.text}
    </div>
);

type ModalFormHeaderAccentButtonProps = React.ButtonHTMLAttributes<any> & {
    branding: BrandingStore;
    text: string;
};

export const ModalFormHeaderAccentButton: FC<ModalFormHeaderAccentButtonProps> = (props) => (
    <div className={styles.modalForm__nameForm} style={{ background: props.branding.branding.accentColor }} {...props}>
        {props.text}
    </div>
);

type ModalFormComponentProps = {
    disableForm: boolean;
    title?: React.ReactNode;
    content: React.ReactNode;
    header: React.ReactNode;
    wider?: boolean;
};

export const ModalFormComponent: FC<ModalFormComponentProps> = ({ disableForm, header, title, content, wider }) => (
    <div className={styles.modalForm__back}>
        <div className={styles.modalForm} onClick={(e) => e.stopPropagation()} style={wider ? { maxWidth: 1100 } : {}}>
            <div className={styles.modalForm__headerContainer}>{header}</div>
            {title && <div className={styles.modalForm__title}>{title}</div>}
            <div
                className={disableForm ? styles.modalForm__formDisabled : styles.modalForm__form}
                style={disableForm ? { pointerEvents: "none" } : {}}
            >
                {content}
            </div>
        </div>
    </div>
);

export type MessageBoxProps = {
    header: string;
    message: React.ReactNode;
    branding: BrandingStore;
    isOpen: boolean;
    discardText?: string;
    onDiscard: () => void;
};

export const MessageBox: FC<MessageBoxProps> = ({ header, message, isOpen, discardText, onDiscard, branding }) => {
    if (!isOpen) return <React.Fragment />;
    return (
        <div className={styles.modalForm__back}>
            <div className={styles.modalForm} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalForm__headerContainer}>
                    <div className={styles.modalForm__nameForm} style={{ background: branding.branding.accentColor }}>
                        {header}
                    </div>
                    <button
                        className={styles.modalForm__closeButton}
                        style={{ background: branding.branding.primaryColor }}
                        onClick={() => onDiscard()}
                    >
                        {discardText || "Закрыть"}
                    </button>
                    <div className={styles.modalForm__title}>{header}</div>
                    <div className={styles.modalForm__form} style={{ marginTop: 10 }}>
                        {message}
                    </div>
                    <div className={styles.modalForm__buttonContainer}>
                        <button
                            className={styles.modalForm__buttonSave}
                            style={{ background: branding.branding.primaryColor }}
                            onClick={() => onDiscard()}
                        >
                            {discardText || "Закрыть"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
