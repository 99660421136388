import { RootStore } from "src/stores/RootStore";
import { computed, observable } from "mobx";
import { SelectableCertificate } from "src/stores/CertificateSelectionStore";
import { Gost34102012KeyType } from "src/api";

export class UserInfoStore {
    @observable certificate?: SelectableCertificate;
    @observable keyType?: Gost34102012KeyType;
    @observable keyPem?: string;
    @observable keyHex?: string;

    constructor(private readonly root: RootStore) {}

    @computed
    get request(): string | undefined {
        if (this.certificate === undefined || this.keyPem === undefined || this.keyHex === undefined) return undefined;
        return JSON.stringify(
            {
                User: this.certificate.innoChainObjectId,
                Key: {
                    KeyType: this.keyTypeToString(),
                    KeyData: this.keyPem,
                },
            },
            null,
            2
        );
    }

    async load(): Promise<void> {
        const certificate = this.root.certificateSelectionStore.value;
        if (certificate === undefined) return;
        const key = await this.root.ruTokenApi.getCertificateKey(certificate);
        const info = await this.root.userRpc.userLogin.convertHexKeyToPem(key);
        this.keyHex = info.value.hex;
        this.keyPem = info.value.pem;
        this.keyType = info.value.type;
        this.certificate = certificate;
    }

    private keyTypeToString() {
        if (this.keyType === Gost34102012KeyType.Gost256) return "gost_2012_256_pem";
        if (this.keyType === Gost34102012KeyType.Gost512) return "gost_2012_512_pem";
    }
}
