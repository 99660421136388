// Модуль UserShell определеяет представление оболочки пользователя в веб-интерфейсе информационной системы. При этом,
// во время навигации между маршрутами веб-интерфейса будет показан загрузочный экран. По завершении навигации будет
// показана страница, определяемая маршрутом и компонентом словаря UserViewMap.

import * as React from "react";
import { RouterView } from "mobx-state-router";
import { UserViewMap } from "src/routes";
import { FC } from "react";
import { useObserver } from "mobx-react";
import { useRootStore } from "src/utilities";
import { Header } from "src/components/Header/Header";
import { Spinner } from "src/components/Spinner/Spinner";

export const UserShell: FC = () => {
    const { rootStore: store } = useRootStore();
    return useObserver(() =>
        store.brandingStore.isLoaded ? (
            <div
                style={{
                    background: store.brandingStore.branding.backgroundColor,
                    backdropFilter: "blur(4px)",
                    height: "100%",
                }}
            >
                <div className="app max-container">
                    <Header
                        logo={store.brandingStore.branding.logoUrl}
                        color={store.brandingStore.branding.foregroundColor}
                        profile={store.certificateSelectionStore.value?.personInfo}
                        branding={store.brandingStore}
                    />
                    <div className="main">
                        {store.routerStore.isTransitioning ? (
                            <Spinner color={store.brandingStore.branding.accentColor} />
                        ) : (
                            <RouterView routerStore={store.routerStore} viewMap={UserViewMap} />
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div />
        )
    );
};
