// Модуль Spinner определяет внешний вид загрузочного экрана веб-интерфейса информационной системы.

import React, { FC } from "react";
import styles from "./snipper.module.css";

type SpinnerProps = {
    color: string;
};

export const Spinner: FC<SpinnerProps> = ({ color }) => {
    return (
        <div className={styles.spinner}>
            <div className={styles.spinner__loader} style={{ borderTop: `16px solid ${color}` }} />
            <div className={styles.spinner__description}>Пожалуйста, подождите...</div>
        </div>
    );
};
