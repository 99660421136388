// Модуль hooks определяет ограничения постраничной навигации приложения. Данные ограничения позволяют заблокировать
// страницы в случае, если не выполнены определённые условия: например, если пользователь не авторизован, не выбран
// сертификат, или авторизация на устройстве RuToken ЭЦП не была произведена.

import { RouteTransitionHook } from "src/routing/route";
import { RouterState } from "mobx-state-router";
import { UserRouteNames } from "src/routes";
import { KnownErrorCode } from "src/stores/RuTokenApi";
import { RootStore } from "src/stores/RootStore";

export const EnsureBrandingLoaded: RouteTransitionHook = async (root) => {
    if (root.brandingStore.isLoaded) return;
    await root.brandingStore.load();
};

export const EnsureLoggedInDevice: RouteTransitionHook = async (root) => {
    let isLoggedIn = false;
    try {
        isLoggedIn = await root.deviceLoginStore.isLoggedInDevice();
    } catch (error) {
        throw new RouterState(UserRouteNames.error);
    }
    if (!isLoggedIn) throw new RouterState(UserRouteNames.deviceLogin);
};

export const RedirectToFormsIfLoggedInDevice: RouteTransitionHook = async (root) => {
    let isLoggedIn = false;
    try {
        isLoggedIn = await root.deviceLoginStore.isLoggedInDevice();
    } catch (error) {
        throw new RouterState(UserRouteNames.error);
    }

    if (!isLoggedIn) return;
    throw new RouterState(UserRouteNames.forms);
};

export const EnsureCertificateSelected: RouteTransitionHook = async (root, _, to) => {
    const certificate = root.certificateSelectionStore.certificate;
    if (!certificate) {
        throw new RouterState(UserRouteNames.certificateSelection);
    }
};

export const DisallowErrorRoute: RouteTransitionHook = async (root, _, to, from) => {
    if (from.routeName == UserRouteNames.error) {
        throw new RouterState(UserRouteNames.error);
    }
};

export const EnsureLoggedIn: RouteTransitionHook = async (root) => {
    const isAuthorized = await root.userRpc.isAuthorized();
    if (isAuthorized) return;
    try {
        await login(root);
    } catch (error) {
        throw new RouterState(UserRouteNames.error);
    }
};

async function login(root: RootStore): Promise<void> {
    const certificate = root.certificateSelectionStore.certificate;
    if (!certificate) {
        throw new RouterState(UserRouteNames.certificateSelection);
    }

    const certificateContent = await root.ruTokenApi.getCertificateContent(certificate);
    const userInfo = await root.userRpc.userLogin.getHolderInfoFromCertificate(certificateContent);
    if (!userInfo.success) {
        root.errorStore.onError(KnownErrorCode.serverBadCertificate);
        throw KnownErrorCode.serverBadCertificate;
    }

    const challenge = await root.userRpc.userLogin.startChallenge(
        userInfo.value.innoChainObjectId,
        userInfo.value.publicKey
    );
    if (!challenge.success) {
        root.errorStore.onError(KnownErrorCode.serverInvalidCredentials);
        throw KnownErrorCode.serverInvalidCredentials;
    }

    const signature = await root.ruTokenApi.sign(challenge.value.signMe, certificate);
    const completion = await root.userRpc.userLogin.completeChallenge(challenge.value.innoChainObjectId, signature);
    if (!completion.success) {
        root.errorStore.onError(KnownErrorCode.serverSignatureInvalid);
        throw KnownErrorCode.serverSignatureInvalid;
    }

    const token = completion.value;
    root.userRpc.setUserToken(token);
}
