// Модуль routes определяет схему маршрутизации, используемую в информационной системе. Модуль содержит словари,
// ставящие в соответствие названию маршрута компонент, словари, ставящие в соответствие названию маршрута шаблон URL,
// используемый для идентификации маршрута браузером.

import * as React from "react";
import { Route } from "mobx-state-router";
import { FormsPage } from "src/pages/FormsPage/FormsPage";
import { convertRoutes } from "src/routing/route";
import {
    DisallowErrorRoute,
    EnsureBrandingLoaded,
    EnsureCertificateSelected,
    EnsureLoggedIn,
    EnsureLoggedInDevice,
    RedirectToFormsIfLoggedInDevice,
} from "src/routing/hooks";
import { ErrorPage } from "src/pages/ErrorPage/ErrorPage";
import { DeviceLoginPage } from "src/pages/DeviceLoginPage/DeviceLoginPage";
import { NotFoundPage } from "src/pages/NotFoundPage/NotFoundPage";
import { CertificateSelectionPage } from "src/pages/CertificateSelectionPage/CertificateSelectionPage";
import { DbgPage } from "src/pages/DbgPage/DbgPage";
import { UserInfoPage } from "src/pages/UserInfoPage/UserInfoPage";
import { UploadPage } from "src/pages/UploadPage/UploadPage";

export enum UserRouteNames {
    notFound = "not-found",
    forms = "forms",
    error = "error",
    deviceLogin = "device-login",
    deviceLogout = "device-logout",
    certificateSelection = "certificate-selection",
    userInfo = "user-info",
    debug = "debug",
    upload = "upload",
}

export const UserViewMap = {
    [UserRouteNames.notFound]: <NotFoundPage />,
    [UserRouteNames.forms]: <FormsPage />,
    [UserRouteNames.error]: <ErrorPage />,
    [UserRouteNames.deviceLogin]: <DeviceLoginPage />,
    [UserRouteNames.certificateSelection]: <CertificateSelectionPage />,
    [UserRouteNames.debug]: <DbgPage />,
    [UserRouteNames.userInfo]: <UserInfoPage />,
    [UserRouteNames.upload]: <UploadPage />,
};

export const UserRoutes: Route[] = convertRoutes([
    {
        pattern: "/not-found",
        name: UserRouteNames.notFound,
        hooks: [EnsureBrandingLoaded],
    },
    {
        pattern: "/certificates",
        name: UserRouteNames.certificateSelection,
        hooks: [EnsureBrandingLoaded, EnsureLoggedInDevice, DisallowErrorRoute],
        onEnter: (root) => root.certificateSelectionStore.load(),
    },
    {
        pattern: "/",
        name: UserRouteNames.forms,
        hooks: [
            EnsureBrandingLoaded,
            EnsureLoggedInDevice,
            EnsureCertificateSelected,
            EnsureLoggedIn,
            DisallowErrorRoute,
        ],
        onEnter: (root) => root.formsStore.load(),
    },
    {
        pattern: "/user-info",
        name: UserRouteNames.userInfo,
        hooks: [EnsureBrandingLoaded, EnsureLoggedInDevice, EnsureCertificateSelected, DisallowErrorRoute],
        onEnter: (root) => root.userInfoStore.load(),
    },
    {
        pattern: "/upload",
        name: UserRouteNames.upload,
        hooks: [EnsureBrandingLoaded, EnsureLoggedInDevice, EnsureCertificateSelected, DisallowErrorRoute],
        onEnter: (root) => root.uploadStore.load(),
    },
    {
        pattern: "/login",
        name: UserRouteNames.deviceLogin,
        hooks: [EnsureBrandingLoaded, RedirectToFormsIfLoggedInDevice],
    },
    {
        pattern: "/error",
        name: UserRouteNames.error,
        hooks: [EnsureBrandingLoaded],
    },
    {
        pattern: "/logout",
        name: UserRouteNames.deviceLogout,
        onEnter: (root) => root.deviceLoginStore.logOutDevice(),
        hooks: [EnsureBrandingLoaded],
    },
    {
        pattern: "/debug",
        name: UserRouteNames.debug,
        hooks: [EnsureBrandingLoaded],
    },
]);
