// Модуль CertificateSelectionStore реализует логику страницы выбора сертификатов, установленных на устройстве РуТокен
// ЭЦП. Поддерживаются только сертификаты с указанным внешним идентификатором InnoChainObjectId, по правилам,
// определяемым на стороне сервера в компоненте CertificateHolderParser.

import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { Certificate } from "src/stores/RuTokenApi";
import { UserRouteNames } from "src/routes";
import { RouterState } from "mobx-state-router";
import { CertificateHolderPersonInfo } from "src/api";

export type SelectableCertificate = {
    personInfo: CertificateHolderPersonInfo;
    innoChainObjectId: string;
    id: string;
};

export class CertificateSelectionStore {
    @observable items: SelectableCertificate[] = [];
    @observable isBusy: boolean = true;
    @observable value?: SelectableCertificate;
    @observable certificate?: Certificate;

    constructor(private readonly root: RootStore) {}

    @computed get canProceed(): boolean {
        return !!this.value && !this.isBusy;
    }

    @action async proceed(): Promise<void> {
        if (!this.value) return;
        this.isBusy = true;

        // Try read certificate content before navigation happens.
        const certificateId = this.value!.id;
        await this.root.ruTokenApi.getCertificateContent({ id: certificateId });
        this.certificate = { id: certificateId };

        await this.root.routerStore.goTo(UserRouteNames.forms);
        this.isBusy = false;
    }

    @action async load(): Promise<void> {
        this.isBusy = true;
        const certificates = await this.getCertificatesOrThrowErrorPage();
        const certificateInfoTasks = certificates.map(async (certificate) => {
            const certificateContent = await this.root.ruTokenApi.getCertificateContent(certificate);
            try {
                const holderInfo = await this.root.userRpc.userLogin.getHolderInfoFromCertificate(certificateContent);
                if (holderInfo.success) {
                    const selectable: SelectableCertificate = {
                        personInfo: holderInfo.value.person,
                        innoChainObjectId: holderInfo.value.innoChainObjectId,
                        id: certificate.id,
                    };
                    return selectable;
                } else {
                    return undefined;
                }
            } catch (e) {
                return undefined;
            }
        });

        const completion = await Promise.all(certificateInfoTasks);
        this.items = completion.filter((item) => item !== undefined) as SelectableCertificate[];
        if (this.items.length === 1) {
            this.value = this.items[0];
            const certificateId = this.value!.id;
            this.certificate = { id: certificateId };
            throw new RouterState(UserRouteNames.forms);
        }

        this.isBusy = false;
    }

    private async getCertificatesOrThrowErrorPage(): Promise<Certificate[]> {
        try {
            return await this.root.ruTokenApi.getCertificates();
        } catch (error) {
            throw new RouterState(UserRouteNames.error);
        }
    }
}
