export enum PaktTransactionType {
    CreateAccount = 0,
    CallContract,
    SetPersonalAccountStorage
}

export interface PaktGostPemKey {
    keyType: "gost_2012_256_pem",
    keyData: string
}

export interface PaktRsaKey {
    keyType: "RSA-SHA256",
    keyData: {
        n: string,
        e: string
    }
}

export type PaktKey = PaktGostPemKey | PaktRsaKey

export interface PaktTransactionPayload {
    timestamp: number,
    key: PaktKey,
    from: string
}

export interface PaktContractCallTransaction extends PaktTransactionPayload {
    path: string,
    method: string,
    arguments: any
}

export interface PaktCreateAccountTransaction extends PaktTransactionPayload {

}

export interface PaktSetPersonalAccountStorageTransaction extends PaktTransactionPayload {
    storageKey: string,
    storageValue: string
}

export type PaktTransaction =
    {
        "type": PaktTransactionType.CreateAccount,
        "payload": PaktCreateAccountTransaction
    } | {
    "type": PaktTransactionType.CallContract,
    "payload": PaktContractCallTransaction
} | {
    "type": PaktTransactionType.SetPersonalAccountStorage,
    "payload": PaktSetPersonalAccountStorageTransaction
}

export interface PaktTransactionWithBlobs {
    transaction: PaktTransaction,
    blobs?: ArrayBuffer[]
}

export interface IPaktSigner {
    sign: (data: ArrayBuffer) => Promise<ArrayBuffer> | ArrayBuffer;
    getSignatureSize: () => number;
}

function sum<TItem>(arr: TItem[], cb: (i: TItem) => number): number {
    let s = 0;
    for (const item of arr)
        s += cb(item);
    return s;
}

class BufferWriter {
    private offset = 0;
    private intBuffer = new Uint32Array(1);
    private buf: Uint8Array;

    constructor(buf: ArrayBuffer) {
        this.buf = new Uint8Array(buf);
    }

    public writeData(data: Uint8Array) {
        this.buf.set(data, this.offset);
        this.offset += data.byteLength;
    }

    public writeBuffer(data: ArrayBuffer) {
        this.writeData(new Uint8Array(data));
    }

    public writeInt(i: number) {
        this.intBuffer[0] = i;
        this.writeBuffer(this.intBuffer.buffer);
    }

    public getOffset = () => this.offset;
}

export function encodePaktTransaction(
    transaction: PaktTransactionWithBlobs,
    signer: IPaktSigner): Promise<ArrayBuffer> {
    return encodePaktTransactionWithCodec(transaction, signer, s => {
        return (new TextEncoder()).encode(s);
    })
}

export async function encodePaktTransactionWithCodec(
    transaction: PaktTransactionWithBlobs,
    signer: IPaktSigner,
    utf8Codec: (s: string) => Uint8Array): Promise<ArrayBuffer> {
    const jsonTest = JSON.stringify(transaction.transaction.payload);
    const json = utf8Codec(jsonTest);


    var requiredSize =
        4 // version
        + 4 // type
        + 4 + json.length
        + 4 // blob count
        + ((transaction.blobs?.length ?? 0) * 4)
        + (transaction.blobs == null ? 0 : sum(transaction.blobs, b => b.byteLength))
        + 4 + signer.getSignatureSize();

    const buffer = new ArrayBuffer(requiredSize);
    const wr = new BufferWriter(buffer);
    // Version = 1
    wr.writeInt(1);
    // Type
    wr.writeInt(transaction.transaction.type);

    // JSON
    wr.writeInt(json.length);
    wr.writeData(json);


    //Blobs
    if (transaction.blobs == null)
        wr.writeInt(0);
    else {
        wr.writeInt(transaction.blobs.length);
        for (const b of transaction.blobs) {
            wr.writeInt(b.byteLength);
            wr.writeBuffer(b);
        }
    }

    // Signature
    wr.writeInt(signer.getSignatureSize());
    const toSign = buffer.slice(0, wr.getOffset());
    const signed = await signer.sign(toSign);
    wr.writeBuffer(signed);
    return buffer;
}
