// Модуль Timer позволяет выполнять некоторую функцию загрузки данных каждые N секунд, пока некоторый компонент
// пользовательского интерфейса информационной системы отображается на экране.

import React from "react";

export class Timer extends React.Component<{ interval: number; onTick: () => any }> {
    id: any;
    render() {
        return null;
    }

    componentDidMount(): void {
        this.id = setInterval(() => {
            this.props.onTick();
        }, this.props.interval);
    }

    componentWillUnmount(): void {
        clearInterval(this.id);
    }
}
