// Модуль DeviceLoginPage реализует страницу авторизации на устройстве РуТокен ЭЦП по паролю. В случае, если устройство
// не обнаружено, данная страница показана не будет. PIN-код РуТокен ЭЦП по умолчанию - 12345678.

import { FC } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import * as React from "react";
import style from "./device-login.module.css";
import { Input } from "reactstrap";

export const DeviceLoginPage: FC = () => {
    const {
        rootStore: { deviceLoginStore: store, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <div className={style.deviceLogin__container}>
                <div className={style.deviceLogin__containerHeading}>Авторизация</div>
                <div className={style.deviceLogin__containerMessage}>
                    Пожалуйста, ведите PIN-код подключённого устройства РуТокен.
                </div>
                <Input
                    type={"password"}
                    disabled={store.isLoggingIn}
                    placeholder={"PIN-код подключённого устройства РуТокен"}
                    value={store.password}
                    onChange={(event) => (store.password = event.target.value)}
                />
                <div
                    className={style.deviceLogin__containerDescription}
                    style={{ cursor: "pointer" }}
                    onClick={() => (store.password = "12345678")}
                >
                    PIN-код по умолчанию: 12345678.
                </div>
                <button
                    className={style.deviceLogin__buttonLogin}
                    style={{ background: branding.branding.primaryColor }}
                    onClick={() => store.logInDevice()}
                    disabled={store.isLoggingIn}
                >
                    Войти на устройство
                </button>
                <div className={style.deviceLogin__containerError}>{store.error}</div>
            </div>
        </div>
    ));
};
