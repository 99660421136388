import { ReactTableStore } from "src/stores/table/ReactTableStore";
import * as React from "react";
import { FC } from "react";
import { useObserver } from "mobx-react";
import ReactPaginate from "react-js-pagination";

export type PaginationProps = {
    store: ReactTableStore<any>;
};

export const StorePagination: FC<PaginationProps> = ({ store }) =>
    useObserver(() =>
        store.totalPages * store.pageSize !== 0 ? (
            <ReactPaginate
                onChange={(pageNumber) => store.setPageSize(store.pageSize, pageNumber - 1)}
                totalItemsCount={store.totalPages * store.pageSize}
                activePage={store.page + 1}
                hideNavigation
                itemsCountPerPage={store.pageSize}
                pageRangeDisplayed={store.pageSize === 3 ? 3 : 5}
            />
        ) : (
            <React.Fragment />
        )
    );
