// Модуль RuTokenApi определяет логику взаимодействия клиентской части информационной системы с устройствами РуТокен
// ЭЦП. Модуль содержит объект RuTokenApi, инкапсулирующий взаимодействие с устройством РуТокен ЭЦП с помощью
// инструментов разработчика РуТокен SDk.

import Plugin from "@aktivco-it/rutoken-plugin-bootstrap/src/index";

export enum KnownErrorCode {
    pinIncorrect = "pin-incorrect",
    noDevicesFound = "no-devices-found",
    tooManyDevicesConnected = "too-many-devices-connected",
    unknownError = "unknown-error",
    pluginNotInitialized = "plugin-not-initialized",
    noCertificatesFound = "no-certificates-found",
    tooManyCertificatesFound = "too-many-certificates-found",
    serverBadCertificate = "server-bad-certificate",
    serverInvalidCredentials = "server-invalid-credentials",
    serverSignatureInvalid = "server-signature-invalid",
}

type DeviceInfo = {
    id: number;
    model: string;
    label: string;
    serial: string;
    isPinCached: boolean;
    type: string;
    description: string;
    isLoggedIn: boolean;
};

export type Certificate = {
    id: string;
};

type Key = {
    id: string;
};

class PluginFunctions {
    async login(deviceInfo: DeviceInfo, password: string): Promise<void> {
        try {
            await Plugin.login(deviceInfo.id, password);
        } catch (error) {
            // @ts-ignore
            if (error.code === 17 || error.code === 2) {
                throw KnownErrorCode.pinIncorrect;
            } else {
                console.warn(error);
                throw KnownErrorCode.unknownError;
            }
        }
    }

    async getCertificates(deviceInfo: DeviceInfo): Promise<Certificate[]> {
        const certificates = await Plugin.enumerateCertificates(deviceInfo.id, Plugin.CERT_CATEGORY_USER);
        if (certificates.length === 0) throw KnownErrorCode.noCertificatesFound;
        return certificates.map((certificateId: string) => {
            return { id: certificateId } as Certificate;
        });
    }

    getCertificateContent(deviceInfo: DeviceInfo, certificate: Certificate): Promise<string> {
        return Plugin.getCertificate(deviceInfo.id, certificate.id);
    }

    async rawSign(deviceInfo: DeviceInfo, key: Key, text: string): Promise<string> {
        const digest = await Plugin.digest(deviceInfo.id, Plugin.HASH_TYPE_GOST3411_12_256, text, { base64: true });
        return await Plugin.rawSign(deviceInfo.id, key.id, digest, { computeHash: false });
    }

    async getKeyByCertificate(deviceInfo: DeviceInfo, certificate: Certificate): Promise<Key> {
        const key = await Plugin.getKeyByCertificate(deviceInfo.id, certificate.id);
        return { id: key };
    }

    async getPublicKeyValue(deviceInfo: DeviceInfo, key: Key): Promise<string> {
        return await Plugin.getPublicKeyValue(deviceInfo.id, key.id, {});
    }

    async getDeviceInfo(): Promise<DeviceInfo> {
        const tokenInfoRequestTypes = [
            Plugin.TOKEN_INFO_MODEL,
            Plugin.TOKEN_INFO_LABEL,
            Plugin.TOKEN_INFO_SERIAL,
            Plugin.TOKEN_INFO_IS_PIN_CACHED,
            Plugin.TOKEN_INFO_DEVICE_TYPE,
            Plugin.TOKEN_INFO_IS_LOGGED_IN,
        ];
        const infoTypes = {
            [Plugin.TOKEN_TYPE_RUTOKEN_PINPAD_2]: "Рутокен PINPad",
            [Plugin.TOKEN_TYPE_RUTOKEN_WEB]: "Рутокен Web",
            [Plugin.TOKEN_TYPE_RUTOKEN_ECP]: "Рутокен ЭЦП",
            [Plugin.TOKEN_TYPE_RUTOKEN_ECP_SC]: "Смарт-карта Рутокен ЭЦП",
            [Plugin.TOKEN_TYPE_UNKNOWN]: "Рутокен Lite",
        };
        const types = {
            [Plugin.TOKEN_TYPE_RUTOKEN_PINPAD_2]: "TOKEN_TYPE_PINPAD_2",
            [Plugin.TOKEN_TYPE_RUTOKEN_WEB]: "TOKEN_TYPE_RUTOKEN_WEB",
            [Plugin.TOKEN_TYPE_RUTOKEN_ECP]: "TOKEN_TYPE_RUTOKEN_ECP",
            [Plugin.TOKEN_TYPE_RUTOKEN_ECP_SC]: "TOKEN_TYPE_RUTOKEN_ECP_SC",
            [Plugin.TOKEN_TYPE_UNKNOWN]: "TOKEN_TYPE_UNKNOWN",
        };

        const devices: number[] = await Plugin.enumerateDevices();
        if (devices.length === 0) {
            throw KnownErrorCode.noDevicesFound;
        }

        if (devices.length > 1) {
            throw KnownErrorCode.tooManyDevicesConnected;
        }

        const device = devices[0];
        const infoRequests = tokenInfoRequestTypes.map((request) => Plugin.getDeviceInfo(device, request));
        const response = await Promise.all(infoRequests);
        return {
            id: device,
            model: response[0] as string,
            label: response[1] as string,
            serial: response[2] as string,
            isPinCached: response[3] as boolean,
            type: types[response[4]] as string,
            description: infoTypes[response[4]] as string,
            isLoggedIn: response[5] as boolean,
        };
    }
}

export class RuTokenApi {
    private pluginInitialized: boolean = false;
    private deviceInfo?: DeviceInfo;

    constructor(private readonly onError: (code: KnownErrorCode) => void) {}

    async getCertificates(): Promise<Certificate[]> {
        const deviceInfo = await this.getDeviceInfo();
        return await this.apiCall((api) => api.getCertificates(deviceInfo));
    }

    async getCertificateContent(certificate: Certificate): Promise<string> {
        const deviceInfo = await this.getDeviceInfo();
        return await this.apiCall((api) => api.getCertificateContent(deviceInfo, certificate));
    }

    async getCertificateKey(certificate: Certificate): Promise<string> {
        const deviceInfo = await this.getDeviceInfo();
        const key = await this.apiCall((api) => api.getKeyByCertificate(deviceInfo, certificate));
        return await this.apiCall((api) => api.getPublicKeyValue(deviceInfo, key));
    }

    async sign(message: string, certificate: Certificate): Promise<string> {
        const deviceInfo = await this.getDeviceInfo();
        const key = await this.apiCall((api) => api.getKeyByCertificate(deviceInfo, certificate));
        return await this.apiCall((api) => api.rawSign(deviceInfo, key, message));
    }

    async isLoggedInDevice(): Promise<boolean> {
        const deviceInfo = await this.getDeviceInfo(true);
        return deviceInfo.isLoggedIn;
    }

    async logInDevice(password: string): Promise<void> {
        const deviceInfo = await this.getDeviceInfo(true);
        await this.apiCall((api) => api.login(deviceInfo, password));
    }

    private async getDeviceInfo(clearCache: boolean = false): Promise<DeviceInfo> {
        if (!clearCache && this.deviceInfo) {
            return this.deviceInfo;
        } else {
            this.deviceInfo = await this.apiCall((api) => api.getDeviceInfo());
            return this.deviceInfo;
        }
    }

    private async apiCall<TReturn>(lambda: (api: PluginFunctions) => Promise<TReturn>): Promise<TReturn> {
        try {
            if (!this.pluginInitialized) {
                await Plugin.init();
                this.pluginInitialized = true;
            }

            return await lambda(new PluginFunctions());
        } catch (error) {
            // @ts-ignore
            const isKnownError = Object.values(KnownErrorCode).includes(error);
            if (isKnownError) {
                throw this.handleError(error as KnownErrorCode);
            } else {
                const errorCodes = Plugin.errorCodes;
                if (!errorCodes) {
                    throw this.handleError(KnownErrorCode.pluginNotInitialized);
                }

                // @ts-ignore
                const errorCode = parseInt(error.message);
                switch (errorCode) {
                    case errorCodes.PIN_INCORRECT:
                        throw this.handleError(KnownErrorCode.pinIncorrect);
                    default:
                        console.warn(error);
                        throw this.handleError(KnownErrorCode.unknownError);
                }
            }
        }
    }

    private handleError(error: KnownErrorCode): KnownErrorCode {
        this.onError(error);
        return error;
    }
}
