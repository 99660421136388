import React, { FC } from "react";
import { useObserver } from "mobx-react";
import { FormGroup } from "reactstrap";
import Editor from "@monaco-editor/react";

type ValidatedMonacoProps = {
    value: string;
    placeholder: string;
    callback?: (x: string) => void;
    maxHeight: string,
    readonly?: boolean
};

export const ValidatedMonaco: FC<ValidatedMonacoProps> = ({ value, placeholder, callback, maxHeight, readonly }) =>
    useObserver(() => {
        return (
            <FormGroup className="mb-2">
                <label style={{ display: "block" }}>
                    <div style={{ marginBottom: 10 }}>
                        {placeholder}
                    </div>
                    <Editor
                        height={maxHeight ?? "30vh"}
                        language={"json"}
                        value={value ?? ""}
                        options={{ wordWrap: "on", readOnly: readonly }}
                        onChange={(event) => (callback ? callback(event ?? "") : null)}
                    />
                </label>
            </FormGroup>
        );
    });
