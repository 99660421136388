// Модуль ErrorStore реализует логику страницы отображения ошибки устройства РуТокен ЭЦП или информационной системы.
// При этом в случае нажатия пользователем кнопки "Попробовать снова" произойдёт перезагрузка веб-приложения, система
// попытается обнаружить устройство и выполнить действие повторно.

import { action, observable } from "mobx";
import { KnownErrorCode } from "src/stores/RuTokenApi";
import { RootStore } from "src/stores/RootStore";
import { UserRouteNames } from "src/routes";

const KnownErrorDescription = {
    [KnownErrorCode.serverBadCertificate]: "Невозможно извлечь данные из сертификата, доступ запрещён",
    [KnownErrorCode.serverInvalidCredentials]: "Ошибка авторизации, доступ запрещён",
    [KnownErrorCode.serverSignatureInvalid]: "Подпись неверна, доступ запрещён",
    [KnownErrorCode.noCertificatesFound]: "На подключённом устройстве сертификаты не найдены",
    [KnownErrorCode.noDevicesFound]: "Устройства РуТокен не обнаружены",
    [KnownErrorCode.pinIncorrect]: "Введён некорректный ПИН",
    [KnownErrorCode.pluginNotInitialized]: "Доступ к API РуТокен невозможен",
    [KnownErrorCode.tooManyCertificatesFound]: "Установлено слишком много пользовательских сертификатов",
    [KnownErrorCode.tooManyDevicesConnected]: "Подключено слишком много устройств РуТокен",
    [KnownErrorCode.unknownError]: "Неизвестная ошибка",
};

export class ErrorStore {
    @observable errorMessage?: string;

    constructor(private readonly root: RootStore) {}

    @action onError(code: KnownErrorCode) {
        this.root.certificateSelectionStore.value = undefined;
        this.errorMessage = KnownErrorDescription[code];
    }

    @action async retry() {
        window.location.reload();
        window.location.replace("/");
    }
}
