// Модуль app является корневым компонентом информационной системы, инициализирует адаптер истории браузера, корневой
// компонент, содержащий логику приложения, корневой компонент, содрежащий оболочку пользовательского интерфейса и
// маршрутизатор постраничной навигации.

import * as React from "react";
import createBrowserHistory from "history/createBrowserHistory";
import "./global.css";
import { observer, Provider } from "mobx-react";
import { RootStore } from "src/stores/RootStore";
import { HistoryAdapter } from "mobx-state-router";
import { UserShell } from "src/components/UserShell";

let root: RootStore;
const ensureInitialized = () => {
    if (root) return;
    root = new RootStore();
    const historyAdapter = new HistoryAdapter(root.routerStore, createBrowserHistory());
    historyAdapter.observeRouterStateChanges();
};

export const App = observer(() => {
    ensureInitialized();
    return (
        <>
            <Provider rootStore={root}>
                <UserShell />
            </Provider>
        </>
    );
});
