import React, { FC } from "react";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { ValidatedMonaco } from "src/components/Monaco/ValidatedMonaco";
import { Spinner } from "src/components/Spinner/Spinner";
import headerStyles from "src/components/Header/header.module.css";
import styles from "src/pages/UserInfoPage/user-info-page.module.css";
import { RouterLink } from "mobx-state-router";
import { UserRouteNames } from "src/routes";
import { Input } from "reactstrap";

export const UserInfoPage: FC = () => {
    const {
        rootStore: { userInfoStore: info, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div>
            {info.request ? (
                <div className={styles.formTable__container}>
                    <h2 className={styles.formTable__title}>Сведения о владельце сертификата</h2>
                    <div className={styles.formTable__content}>
                        <div className={headerStyles.headerProfileName}>
                            <RouterLink routeName={UserRouteNames.userInfo}>
                                {info.certificate?.personInfo.surName ?? ""}{" "}
                                {info.certificate?.personInfo.givenName ?? "Имя не указано"}
                            </RouterLink>
                        </div>
                        <div className={headerStyles.headerProfileCompany}>
                            {info.certificate?.personInfo.position ?? "должность не указана"}
                            {", "}
                            {info.certificate?.personInfo.organization ?? "организация не указана"}
                        </div>
                        <div style={{ display: "block", marginTop: 15 }}>
                            <div>ID пользователя</div>
                            <Input type={"textarea"} disabled={true} value={info.certificate?.innoChainObjectId} />
                        </div>
                        <div style={{ display: "block", marginTop: 5 }}>
                            <div>Ключ в формате HEX</div>
                            <Input type={"textarea"} disabled={true} value={info.keyHex} />
                        </div>
                        <div style={{ display: "block", marginTop: 5 }}>
                            <div>Ключ в формате PEM</div>
                            <Input type={"textarea"} disabled={true} value={info.keyPem} style={{ minHeight: 120 }} />
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <ValidatedMonaco
                                maxHeight={"30vh"}
                                value={info.request}
                                placeholder={"Параметры запроса создания или обновления ключа пользователя"}
                                readonly={true}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <Spinner color={branding.branding.accentColor} />
            )}
        </div>
    ));
};
