import {RootStore} from "src/stores/RootStore";
import {action, observable} from "mobx";
import { uniqueId } from "src/utilities";

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export class DebugStore {
    @observable jsonSchemaRaw: string = "";
    @observable jsonFormRaw: string = "";
    @observable jsonSchema: any = {};
    @observable jsonForm: any = {};
    @observable disabled: boolean = false;

    constructor(private readonly root: RootStore) {
        this.jsonForm = {};
        this.jsonFormRaw = "{}";
        this.jsonSchemaRaw = `{
  "$schema": "http://json-schema.org/draft-04/schema#",
  "description": "Заявка",
  "id": "a6kly",
  "properties": {
    "numberAlt": {
      "title": "Температура",
      "type": "number",
      "multipleOf": 0.01
    },
    "dictionarySelect": {
      "title": "Выбор из списка",
      "type": "string",
      "ui:widget": "DictionaryWidget",
      "ui:options": {
        "dictionaryIdentifier": "example-dictionary"
      }
    }
  },
  "required": [
    "numberAlt",
    "dictionarySelect"
  ],
  "title": "Campaign",
  "type": "object"
}`;
        this.jsonSchema = JSON.parse(this.jsonSchemaRaw);
    }

    @action toggleDisabled() {
        this.disabled = !this.disabled;
    }

    @action handleFormDataChange(formData: any) {
        this.jsonForm = formData;
        this.jsonFormRaw = JSON.stringify(formData, null, 2);
    }

    @action handleFormDataTextChange(formData: string) {
        this.jsonForm = JSON.parse(formData);
        this.jsonFormRaw = formData;
    }

    @action async update() {
        const schema = JSON.parse(this.jsonSchemaRaw);
        schema["id"] = uniqueId();
        this.jsonSchemaRaw = JSON.stringify(schema, null, 2);
        this.jsonSchema = schema;
        this.jsonForm = {};
        this.jsonFormRaw = "{}";
    }
}