// Модуль Header определяет заголовок страниц веб-интерфейса информационной системы.

import React, { FC } from "react";
import styles from "./header.module.css";
import aeroLogo from "../../assets/img/logo.png";
import innoLogo from "../../assets/img/innopolis.png";
import udevLogo from "../../assets/img/udev.png";
import { CertificateHolderPersonInfo } from "src/api";
import { RouterLink } from "mobx-state-router";
import { UserRouteNames } from "src/routes";
import { useObserver } from "mobx-react";
import { BrandingStore } from "src/stores/BrandingStore";

type HeaderProps = {
    profile?: CertificateHolderPersonInfo;
    branding: BrandingStore;
    color: string;
    logo: string;
};

export const Header: FC<HeaderProps> = ({ logo, color, profile, branding }) => {
    const image =
        logo === "innopolis" ? innoLogo : logo === "aero" ? aeroLogo : logo === "udev" ? udevLogo : logo ?? aeroLogo;
    return useObserver(() => (
        <header className={styles.header}>
            <RouterLink routeName={UserRouteNames.forms}>
                <img className={styles.headerImage} src={image} alt="logo" />
            </RouterLink>
            {profile && (
                <div className={styles.headerProfile} style={{ color: color }}>
                    <div className={styles.headerProfileName}>
                        <RouterLink routeName={UserRouteNames.userInfo}
                                    style={{ color: branding.branding.foregroundColor }}>
                            {profile.surName ?? ""} {profile.givenName ?? "Имя не указано"}
                        </RouterLink>
                    </div>
                    <div className={styles.headerProfileCompany}>
                        {profile.position ?? "должность не указана"}
                        {", "}
                        {profile.organization ?? "организация не указана"}
                    </div>
                </div>
            )}
        </header>
    ));
};
