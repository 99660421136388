// Модуль FormsPage реализует страницу отображения форм на заполнение, а также отправленных форм, отправляемых форм и
// отправляемых запросов на запуск новых бизнес-процессов. Заполненные формы можно просматривать в окне заполнения
// формы в режиме только для чтения.

import { shadeColor, toRussianDate, useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import * as React from "react";
import { FC, useState } from "react";
import styles from "./forms-page.module.css";
import { InnoFormBriefDto, InnoFormFullDto, StartedDiagramDto } from "src/api";
import { DiagramStore, FormsStore, PublishedDiagramModalStore, PublishedDiagramStore } from "src/stores/FormsStore";
import { Timer } from "src/utils/timer";
import { ReactBpmnEditor } from "src/components/ReactBpmnEditor/ReactBpmnEditor";
import { AutoCompleteSelect } from "src/components/AutoCompleteSelect/AutoCompleteSelect";
import { BrandingStore } from "src/stores/BrandingStore";
import { JsonSchemaForm } from "src/components/JsonSchemaForm/JsonSchemaForm";
import {
    MessageBox,
    ModalFormAccentButton,
    ModalFormButtonContainer,
    ModalFormComponent,
    ModalFormHeaderAccentButton,
    ModalFormHeaderPrimaryButton,
    ModalFormPrimaryButton,
} from "src/components/MessageBox/MessageBox";
import { StorePagination } from "src/components/Pagination/Pagination";

const FormHeader: FC<{ store: FormsStore; branding: BrandingStore }> = ({ store, branding }) => {
    return useObserver(() => (
        <div className={styles.formHeader}>
            <button
                className={styles.button_blue}
                disabled={!store.diagrams.canStart}
                style={{ opacity: store.diagrams.canStart ? 1 : 0.5, background: branding.branding.accentColor }}
                onClick={() => store.diagrams.open()}
            >
                Создать
            </button>
            <div className={styles.formHeaderActions}>
                <button
                    className={styles.button_blue}
                    style={{ background: branding.branding.accentColor }}
                    disabled={!store.published && !store.publishedDiagrams.isOpen}
                    onClick={() => store.togglePublished(false)}
                >
                    На заполнение
                </button>
                <button
                    className={styles.button_blue}
                    style={{ background: branding.branding.primaryColor }}
                    disabled={store.published && !store.publishedDiagrams.isOpen}
                    onClick={() => store.togglePublished(true)}
                >
                    Отправленные формы
                </button>
                <button
                    className={styles.button_blue}
                    style={{ background: branding.branding.primaryColor }}
                    disabled={store.publishedDiagrams.isOpen}
                    onClick={() => store.publishedDiagrams.open()}
                >
                    Реестр смарт-контрактов
                </button>
            </div>
            <MessageBox
                header={"Данные отправлены"}
                branding={branding}
                message={"Операция выполнена успешно! Вы можете закрыть данное окно."}
                isOpen={store.diagrams.isSuccessModalOpen}
                onDiscard={() => (store.diagrams.isSuccessModalOpen = false)}
            />
        </div>
    ));
};

type FormItemProps = {
    store: FormsStore;
    branding: BrandingStore;
    form: InnoFormBriefDto;
};

const FormItem: FC<FormItemProps> = ({ store, form, branding }) => {
    const [hover, setHover] = useState(false);
    return useObserver(() => {
        const style = { background: hover ? branding.branding.primaryColor : undefined };
        return (
            <div
                className={styles.formTable__item}
                onClick={() => store.openModal(form.id)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={style}
                tabIndex={0}
            >
                <div className={styles.formTable__itemHead} style={{ color: hover ? "white" : "inherit" }}>
                    <div className={styles.formTable__itemName} style={{ color: hover ? "white" : "inherit" }}>
                        <b>{form.title}</b>{" "}
                        <small style={{ opacity: 0.5, fontWeight: "bold" }}>{form.contractAccountId}</small>
                    </div>
                    <div className={styles.formTable__itemDate}>{toRussianDate(form.createdAt)}</div>
                </div>
                <div className={styles.formTable__itemText} style={{ color: hover ? "white" : "inherit" }}>
                    {form.innoChainObjectId ? `Внешний идентификатор: ${form.innoChainObjectId}` : "Для всех аккаунтов"}
                </div>
                {form.isPublished ? (
                    <React.Fragment>
                        <div
                            className={styles.formTable__itemBadge}
                            style={{ backgroundColor: colorizeStatus(form.status) }}
                        >
                            {form.status}
                        </div>
                        {form.publishedAt && (
                            <div className={styles.formTable__itemBadge}>
                                Отправлено: {toRussianDate(form.publishedAt)}
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className={styles.formTable__itemBadge}>{"Требует заполнения"}</div>
                    </React.Fragment>
                )}
            </div>
        );
    });
};

const colorizeStatus = (status: string) => {
    const words = {
        ["Committed"]: "#43A047",
        ["SentToConsensus"]: "#4CAF50",
    };
    return words[status] ?? "#66BB6A";
};

type PublishedDiagramItemProps = {
    store: PublishedDiagramStore;
    diagram: StartedDiagramDto;
    branding: BrandingStore;
};

const PublishedDiagramItem: FC<PublishedDiagramItemProps> = ({ store, diagram, branding }) => {
    const [hover, setHover] = useState(false);
    const [downloadButtonHover, setDownloadButtonHover] = useState(false);
    const color = diagram.diagramStatusColor ?? branding.branding.accentColor;
    return useObserver(() => {
        const style = { background: hover ? branding.branding.primaryColor : undefined };
        return (
            <div
                className={styles.formTable__item}
                tabIndex={0}
                onClick={() => store.modal.open(diagram.id)}
                style={style}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className={styles.formTable__itemHead} style={{ color: hover ? "white" : "inherit" }}>
                    <div className={styles.formTable__itemName} style={{ color: hover ? "white" : "inherit" }}>
                        <b>{diagram.title}</b> <small style={{ opacity: 0.5 }}>{diagram.id}</small>
                    </div>
                    <div className={styles.formTable__itemDate}>{toRussianDate(diagram.createdAt)}</div>
                </div>
                <div className={styles.formTable__itemText} style={{ color: hover ? "white" : "inherit" }}>
                    {`Состояние обновлено: ${toRussianDate(diagram.updatedAt)}`}
                </div>
                <div>
                    {diagram.status && (
                        <React.Fragment>
                            {diagram.diagramStatus && (
                                <div
                                    className={styles.formTable__itemBadge}
                                    style={{ backgroundColor: shadeColor(color, -30) }}
                                >
                                    {diagram.diagramStatus}
                                </div>
                            )}
                            <div
                                className={styles.formTable__itemBadge}
                                style={{ backgroundColor: colorizeStatus(diagram.status) }}
                            >
                                {diagram.status}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {diagram.fileNames.map(file => (
                    <button
                        key={file.id}
                        className={styles.button_blue}
                        onMouseEnter={() => setDownloadButtonHover(true)}
                        onMouseLeave={() => setDownloadButtonHover(false)}
                        style={{
                            opacity: downloadButtonHover ? 0.7 : 1,
                            background: branding.branding.accentColor,
                            borderRadius: 5,
                            textTransform: "none",
                            padding: "9px 12px",
                            marginBottom: -5,
                            marginTop: 6,
                        }}
                        onClick={async (e) => {
                            e.stopPropagation();
                            await store.download(diagram, file);
                        }}
                    >
                        Скачать файл {file.fileName}
                    </button>
                ))}
            </div>
        );
    });
};

export type PublishedDiagramTableProps = {
    store: PublishedDiagramStore;
    branding: BrandingStore;
};

const PublishedDiagramTable: FC<PublishedDiagramTableProps> = ({ store, branding }) => {
    return useObserver(() => (
        <div className={styles.formTable__container}>
            <h2 className={styles.formTable__title}>Реестр</h2>
            <Timer interval={5000} onTick={() => store.refresh()} />
            <div className={styles.formTable}>
                {store.items.map((diagram, index) => (
                    <PublishedDiagramItem key={index} store={store} diagram={diagram} branding={branding} />
                ))}
                {store.items.length === 0 && (
                    <div className={styles.formTable__nothing}>По данным критериям ничего не найдено.</div>
                )}
            </div>
            <StorePagination store={store} />
        </div>
    ));
};

type FormTableProps = {
    store: FormsStore;
    branding: BrandingStore;
};

const FormTable: FC<FormTableProps> = ({ store, branding }) => {
    return useObserver(() => (
        <div className={styles.formTable__container}>
            <h2 className={styles.formTable__title}>Формы</h2>
            <Timer interval={5000} onTick={() => store.refresh()} />
            <div className={styles.formTable}>
                {store.items.map((form, index) => (
                    <FormItem key={`itemAero ${index}`} store={store} form={form} branding={branding} />
                ))}
                {store.items.length === 0 && (
                    <div className={styles.formTable__nothing}>По данным критериям ничего не найдено.</div>
                )}
            </div>
            <StorePagination store={store} />
        </div>
    ));
};

type ModalFormProps = {
    store: FormsStore;
    branding: BrandingStore;
    form: InnoFormFullDto;
};

const ModalForm: FC<ModalFormProps> = ({ form, store, branding }) => {
    return useObserver(() => (
        <ModalFormComponent
            disableForm={store.disableForm}
            header={
                <React.Fragment>
                    <ModalFormHeaderAccentButton branding={branding} text={`ФОРМА ${form.id}`} />
                    <ModalFormHeaderAccentButton branding={branding} text={toRussianDate(form.createdAt)} />
                    <ModalFormHeaderPrimaryButton
                        branding={branding}
                        text={"Закрыть"}
                        disabled={store.isBusy}
                        onClick={() => store.closeModal()}
                    />
                </React.Fragment>
            }
            content={
                <JsonSchemaForm
                    jsonSchema={store.jsonSchema}
                    formData={store.formData}
                    onFormDataChange={(formData) => (store.formData = formData)}
                    disabled={store.isBusy}
                >
                    <ModalFormButtonContainer>
                        <ModalFormPrimaryButton
                            branding={branding}
                            text={"Сохранить"}
                            disabled={store.disableForm}
                            onClick={() => store.saveForm()}
                        />
                        <ModalFormAccentButton
                            branding={branding}
                            text={"Подписать и отправить"}
                            disabled={store.disableForm}
                            onClick={() => store.publishForm()}
                        />
                    </ModalFormButtonContainer>
                </JsonSchemaForm>
            }
        />
    ));
};

type ModalPublishedDiagramProps = {
    store: PublishedDiagramModalStore;
    branding: BrandingStore;
};

const ModalPublishedDiagram: FC<ModalPublishedDiagramProps> = ({ store, branding }) => {
    return useObserver(() => (
        <ModalFormComponent
            wider={true}
            disableForm={false}
            header={
                <React.Fragment>
                    {store.diagram?.title && (
                        <ModalFormHeaderAccentButton branding={branding} text={store.diagram?.title ?? ""} />
                    )}
                    <ModalFormHeaderAccentButton branding={branding} text={store.diagram?.diagramStatus ?? "Новая"} />
                    <ModalFormHeaderPrimaryButton
                        branding={branding}
                        text={"Закрыть"}
                        disabled={!store.isOpen || store.isBusy}
                        onClick={() => store.close()}
                    />
                </React.Fragment>
            }
            content={
                <React.Fragment>
                    <Timer interval={2000} onTick={() => store.refresh()} />
                    {store.diagram?.diagramXml && (
                        <ReactBpmnEditor diagram={store.diagram} branding={branding.branding.bpmn} />
                    )}
                </React.Fragment>
            }
        />
    ));
};

type ModalDiagramProps = {
    store: DiagramStore;
    branding: BrandingStore;
};

const ModalDiagram: FC<ModalDiagramProps> = ({ store, branding }) => {
    return useObserver(() => (
        <ModalFormComponent
            disableForm={false}
            header={
                <React.Fragment>
                    <ModalFormHeaderAccentButton branding={branding} text={"Создать"} />
                    <ModalFormHeaderPrimaryButton
                        branding={branding}
                        text={"Закрыть"}
                        disabled={!store.isOpen || store.isBusy}
                        onClick={() => store.close()}
                    />
                </React.Fragment>
            }
            content={
                <React.Fragment>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <AutoCompleteSelect disabled={store.isBusy} store={store} placeholder={"Введите название..."} />
                    </div>
                    {store.value && (
                        <div>
                            <div style={{ fontWeight: "bold" }}>Псевдоним контракта:</div>
                            <code style={{ marginTop: 8, display: "inline-block" }}>{store.value.chainId}</code>
                        </div>
                    )}
                    <div className={styles.modalForm__form}>
                        <JsonSchemaForm
                            jsonSchema={store.jsonSchema}
                            formData={store.formData}
                            onFormDataChange={(formData) => (store.formData = formData)}
                            disabled={store.isBusy}
                        >
                            <ModalFormButtonContainer>
                                <ModalFormAccentButton
                                    text={"Подписать и отправить"}
                                    branding={branding}
                                    disabled={!store.value || store.isBusy}
                                    onClick={() => store.start()}
                                />
                                <ModalFormPrimaryButton
                                    branding={branding}
                                    text={"Загрузить новый"}
                                    disabled={store.isBusy}
                                    onClick={() => store.upload()}
                                />
                            </ModalFormButtonContainer>
                        </JsonSchemaForm>
                    </div>
                </React.Fragment>
            }
        />
    ));
};

export const FormsPage: FC = () => {
    const {
        rootStore: { formsStore: store, brandingStore: branding },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <FormHeader store={store} branding={branding} />
            {store.publishedDiagrams.isOpen ? (
                <PublishedDiagramTable store={store.publishedDiagrams} branding={branding} />
            ) : (
                <FormTable store={store} branding={branding} />
            )}
            {!!store.form && <ModalForm branding={branding} form={store.form} store={store} />}
            {store.diagrams.isOpen && <ModalDiagram store={store.diagrams} branding={branding} />}
            {store.publishedDiagrams.modal.isOpen && (
                <ModalPublishedDiagram branding={branding} store={store.publishedDiagrams.modal} />
            )}
        </div>
    ));
};
