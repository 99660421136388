import Form from "@rjsf/core";
import TextWidget from "@rjsf/core/lib/components/widgets/TextWidget";
import SelectWidget from "@rjsf/core/lib/components/widgets/SelectWidget";
import metaSchemaDraft04 from "ajv/lib/refs/json-schema-draft-04.json";
import metaSchemaDraft06 from "ajv/lib/refs/json-schema-draft-06.json";
import metaSchemaSecure from "ajv/lib/refs/json-schema-secure.json";
import {useEffect, useState} from "react";
import {useRootStore} from "src/utilities";
import {AjvError} from "react-jsonschema-form";
import * as React from "react";
import {useObserver} from "mobx-react";

type AsyncComponentProps<TResponse> = {
    fetch: () => Promise<TResponse>;
    render: (response: TResponse) => JSX.Element;
    fallback?: JSX.Element;
};

export function AsyncComponent<TResponse>(props: AsyncComponentProps<TResponse>): JSX.Element {
    const [response, setResponse] = useState<TResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        setLoading(true);
        props
            .fetch()
            .then((response: TResponse) => {
                setResponse(response);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [props.fetch]);
    if (response) return <div>{props.render(response)}</div>;
    if (loading) return <div>Загрузка...</div>;
    return <div>{error}</div>;
}

const TemperatureWidget = (props: any) => {
    const digit = parseFloat(props.value ?? 0);
    const negative = digit > 0 ? digit * (-1) : digit;
    const fixed = (Math.round(negative * 100) / 100).toFixed(2);
    return <TextWidgetWithPlaceholder {...props} value={fixed} />
};

const TextWidgetWithPlaceholder = (props: any) => {
    return <TextWidget {...props} placeholder={props.label} />;
};

type SelectOptions = {
    enumOptions: {
        value: string,
        label: string
    }[]
};

const DictionaryWidget = (props: any) => {
    const { rootStore } = useRootStore();
    return <AsyncComponent
        render={options => <SelectWidget {...props} options={options} />}
        fetch={async () => {
            const dictionaryIdentifier: string = props.options["dictionaryIdentifier"];
            const options: SelectOptions = { enumOptions: [] };
            if (dictionaryIdentifier) {
                const dictionary = await rootStore.anonRpc.anonDictionary.getDictionary(dictionaryIdentifier);
                if (dictionary.success && dictionary.value) {
                    dictionary.value.items.forEach(item => {
                        options.enumOptions.push({
                            value: item.value,
                            label: item.displayName
                        });
                    });
                    return options;
                } else {
                    options.enumOptions = [{ value: "-1", label: "Данные не найдены." }];
                    return options;
                }
            } else {
                options.enumOptions = [{ value: "-1", label: "Данные не найдены." }];
                return options;
            }
        }}  />;
}

const widgets = {
    TextWidget: TextWidgetWithPlaceholder,
    TemperatureWidget: TemperatureWidget,
    DictionaryWidget: DictionaryWidget,
};

// See: https://github.com/ajv-validator/ajv-i18n/blob/master/messages/index.js
const russianMessages = {
    ["required"]: "данное поле является обязательным",
    ["maximum"]: "значение должно быть {{comparison}} {{limit}}",
    ["minimum"]: "значение должно быть {{comparison}} {{limit}}",
    ["multipleOf"]: "значение должно быть кратно {{multipleOf}}",
    ["type"]: "данное значение должно иметь тип '{{type}}'"
};

const russianWords = {
    ["integer"]: "целое число",
    ["boolean"]: "логический",
};

function transformErrors(errors: AjvError[]) {
    return errors.map(error => {
        const name = error.name;
        const messageTemplate: string = russianMessages[name];
        if (messageTemplate) {
            let message = messageTemplate;
            Object.keys(error.params).forEach(key => {
                const pattern = `{{${key}}}`;
                const value = error.params[key];
                const valueTranslation = russianWords[value];
                if (valueTranslation) {
                    message = message.replace(pattern, valueTranslation);
                } else {
                    message = message.replace(pattern, value);
                }
            });
            error.message = message;
            return error;
        } else {
            return error;
        }
    });
}

type JsonSchemaFormProps = {
    jsonSchema: any,
    formData: any,
    onFormDataChange: (formData: any) => void,
    disabled: boolean,
    children: React.ReactNode;
}

export const JsonSchemaForm = (props: JsonSchemaFormProps) => useObserver(() => {
    const json = props.jsonSchema;
    if (json === undefined) {
        return <div />
    }

    const ui = json["properties"] ? json["properties"] : {};
    const meta = [metaSchemaDraft04, metaSchemaDraft06, metaSchemaSecure];
    return (
        <Form
            schema={json}
            uiSchema={ui}
            widgets={widgets}
            formData={props.formData}
            onChange={(e) => (props.onFormDataChange(e.formData))}
            additionalMetaSchemas={meta}
            showErrorList={false}
            liveValidate={true}
            disabled={props.disabled}
            transformErrors={transformErrors}
        >
            {props.children}
        </Form>
    );
});
