// Модуль RootStore является корневым компонентом, содержащим объекты, реализующие логику страниц информационной
// системы. Компонент SecureCoreApi также определяет поведение клиента для RPC, осуществляющего подписанные
// авторизационным токеном запросы на сервер RPC.

import { action, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { UserRoutes } from "src/routes";
import { FormsStore } from "src/stores/FormsStore";
import { CoreApi } from "src/api";
import { ErrorStore } from "src/stores/ErrorStore";
import { DeviceLoginStore } from "src/stores/DeviceLoginStore";
import { RuTokenApi } from "src/stores/RuTokenApi";
import { CertificateSelectionStore } from "src/stores/CertificateSelectionStore";
import { BrandingStore } from "src/stores/BrandingStore";
import { DebugStore } from "src/stores/DebugStore";
import { UserInfoStore } from "src/stores/UserInfoStore";
import { UploadStore } from "src/stores/UploadStore";

type AuthHeaderKeys = "X-User-Auth";

const apiUrl = "/tsrpc";

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null = null;

    constructor(path: string, authHeaderKey: AuthHeaderKeys) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = {};
            if (this.token) {
                request.headers[authHeaderKey] = this.token;
            }

            const response = await fetch(url, request);
            if (response.status == 401) {
                window.location.reload();
                this.resetUserToken();
                await new Promise(() => {});
            }

            return response;
        });
    }

    async isAuthorized(): Promise<boolean> {
        if (this.token === null) return false;
        return await this.userLogin.isAuthorized(this.token);
    }

    @action setUserToken(token: string) {
        this.token = token;
    }

    @action resetUserToken() {
        this.token = null;
    }
}

export class RootStore {
    @observable userRpc = new SecureCoreApi(apiUrl, "X-User-Auth");
    @observable anonRpc = new CoreApi(apiUrl);
    @observable routerStore: RouterStore = new RouterStore(this, UserRoutes, new RouterState("not-found"));
    @observable deviceLoginStore: DeviceLoginStore = new DeviceLoginStore(this);
    @observable debugStore: DebugStore = new DebugStore(this);
    @observable formsStore: FormsStore = new FormsStore(this);
    @observable errorStore: ErrorStore = new ErrorStore(this);
    @observable uploadStore: UploadStore = new UploadStore(this);
    @observable userInfoStore: UserInfoStore = new UserInfoStore(this);
    @observable brandingStore: BrandingStore = new BrandingStore(this);
    @observable certificateSelectionStore: CertificateSelectionStore = new CertificateSelectionStore(this);
    @observable ruTokenApi: RuTokenApi = new RuTokenApi((code) => this.errorStore.onError(code));
}
