// Модуль FormsStore реализует логику страницы отображения форм на заполнение, а также отправленных форм, отправляемых
// форм и отправляемых запросов на запуск новых бизнес-процессов. Заполненные формы можно просматривать в окне
// заполнения формы в режиме только для чтения.

import { action, computed, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import {
    InnoFormBriefDto,
    InnoFormFullDto,
    ParameterSelectionMode,
    StartableDiagramDto,
    StartedDiagramDto, StartedDiagramFileNameDto,
    StartedDiagramFullDto,
} from "src/api";
import { ReactTableStore } from "src/stores/table/ReactTableStore";
import { UserRouteNames } from "src/routes";
import { AutoCompleteSelectStore } from "../components/AutoCompleteSelect/AutoCompleteSelectStore";
import { encodePaktTransaction, PaktTransactionType } from "src/stores/pakt";
import { RuTokenPaktSigner, toBase64 } from "src/stores/RuTokenPaktSigner";
import { uniqueId } from "src/utilities";

export class DiagramStore implements AutoCompleteSelectStore<StartableDiagramDto> {
    readonly labelField: string = "title";
    readonly valueField: string = "id";

    @observable value?: StartableDiagramDto;
    @observable isBusy: boolean = false;
    @observable isOpen: boolean = false;
    @observable items: StartableDiagramDto[] = [];
    @observable isSuccessModalOpen: boolean = false;
    @observable formData: any;

    constructor(private readonly root: RootStore) {}

    @computed get canStart(): boolean {
        return !this.root.formsStore.isBusy && !this.isBusy;
    }

    @computed get jsonSchema(): object {
        const schema = this.value?.jsonSchema;
        if (!schema) return {};
        schema["id"] = uniqueId();
        return schema;
    }

    @action
    async open(): Promise<void> {
        this.isBusy = true;
        this.value = undefined;
        try {
            const mode = await this.root.userRpc.user.getStartableDiagramSelectionMode();
            if (mode === ParameterSelectionMode.Manual) {
                this.isOpen = true;
            } else if (mode === ParameterSelectionMode.AutoInnoChainObjectId) {
                await this.startWithInnoChainObjectId();
            } else {
                alert("Unknown parameter selection mode. Please ensure your app is properly configured.");
            }
        } catch (e) {
            alert("Error occured while trying to start the process: " + e);
        } finally {
            this.isBusy = false;
        }
    }

    @action
    async start(): Promise<void> {
        this.isBusy = true;
        try {
            const mode = await this.root.userRpc.user.getStartableDiagramSelectionMode();
            if (mode === ParameterSelectionMode.Manual) {
                await this.startManually();
            } else {
                alert("Unable to manually start the app. Please ensure your app is properly configured.");
            }
        } catch (e) {
            alert("Error occured while trying to start the process: " + e);
        } finally {
            this.isBusy = false;
        }
    }

    @action
    async upload(): Promise<void> {
        this.isOpen = false;
        const router = this.root.routerStore;
        await router.goTo(UserRouteNames.upload);
    }

    @action close() {
        this.isOpen = false;
        this.formData = {};
    }

    @action
    async suggest(query: string): Promise<void> {
        const response = await this.root.userRpc.user.getStartableDiagrams(query, true, 0, 10);
        this.items = response.items;
    }

    @action
    private async startManually(): Promise<void> {
        this.isBusy = true;
        try {
            const diagram = this.value!;
            const certificateInfo = this.root.certificateSelectionStore;
            const certificate = certificateInfo.certificate!;
            const innoChainObjectId = certificateInfo.value?.innoChainObjectId!;
            const key = await this.root.ruTokenApi.getCertificateKey(certificate);
            const info = await this.root.userRpc.userLogin.convertHexKeyToPem(key);
            const signer = new RuTokenPaktSigner(this.root.ruTokenApi, certificate);
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            const args = this.formData ? this.formData : {};
            const transaction = await encodePaktTransaction(
                {
                    transaction: {
                        type: PaktTransactionType.CallContract,
                        payload: {
                            method: "start",
                            path: "contracts",
                            arguments: {
                                Identifier: diagram.chainId,
                                Arguments: {
                                    Data: args,
                                },
                            },
                            key: {
                                keyType: "gost_2012_256_pem",
                                keyData: info.value.pem,
                            },
                            from: innoChainObjectId,
                            timestamp: timestampInSeconds,
                        },
                    },
                    blobs: [],
                },
                signer
            );

            const encoded = toBase64(transaction);
            const json = JSON.stringify(args);
            const publication = await this.root.userRpc.user.publishDiagram(diagram.id, json, encoded);
            if (publication.length === 0) {
                this.close();
                this.isSuccessModalOpen = true;
            } else {
                const errorMessage = "Unable to publish diagram: " + JSON.stringify(publication);
                alert(errorMessage);
            }
        } catch (e) {
            console.warn(e);
            await this.root.routerStore.goTo(UserRouteNames.error);
        } finally {
            this.isBusy = false;
        }
    }

    @action
    private async startWithInnoChainObjectId(): Promise<void> {
        this.isBusy = true;
        try {
            const diagram = await this.root.userRpc.user.getStartableDiagram();
            const certificateInfo = this.root.certificateSelectionStore;
            const certificate = certificateInfo.certificate!;
            const innoChainObjectId = certificateInfo.value?.innoChainObjectId!;
            const key = await this.root.ruTokenApi.getCertificateKey(certificate);
            const info = await this.root.userRpc.userLogin.convertHexKeyToPem(key);
            const signer = new RuTokenPaktSigner(this.root.ruTokenApi, certificate);
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            const transaction = await encodePaktTransaction(
                {
                    transaction: {
                        type: PaktTransactionType.CallContract,
                        payload: {
                            method: "start",
                            path: "contracts",
                            arguments: {
                                Identifier: diagram.chainId,
                                Arguments: {},
                            },
                            key: {
                                keyType: "gost_2012_256_pem",
                                keyData: info.value.pem,
                            },
                            from: innoChainObjectId,
                            timestamp: timestampInSeconds,
                        },
                    },
                    blobs: [],
                },
                signer
            );

            const encoded = toBase64(transaction);
            const publication = await this.root.userRpc.user.publishDiagram(diagram.id, "{}", encoded);
            if (publication.length === 0) {
                this.close();
                this.isSuccessModalOpen = true;
            } else {
                this.root.errorStore.errorMessage = "Unable to publish diagram: " + JSON.stringify(publication);
                await this.root.routerStore.goTo(UserRouteNames.error);
            }
        } catch (e) {
            console.warn(e);
            await this.root.routerStore.goTo(UserRouteNames.error);
        } finally {
            this.isBusy = false;
        }
    }
}

export class PublishedDiagramModalStore {
    @observable isBusy: boolean = false;
    @observable isOpen: boolean = false;
    @observable diagram?: StartedDiagramFullDto;

    constructor(private readonly root: RootStore) {}

    @action
    async refresh(): Promise<void> {
        try {
            if (!this.diagram?.id) return;
            this.diagram = await this.root.userRpc.user.getStartedDiagramById(this.diagram.id);
        } catch {}
    }

    @action
    async open(startedDiagramBpmnId: string): Promise<void> {
        this.isBusy = true;
        this.isOpen = true;
        this.diagram = undefined;
        try {
            this.diagram = await this.root.userRpc.user.getStartedDiagramById(startedDiagramBpmnId);
        } catch {
            this.isOpen = false;
        } finally {
            this.isBusy = false;
        }
    }

    @action
    async close(): Promise<void> {
        this.isOpen = false;
        this.diagram = undefined;
        this.isBusy = false;
    }
}

export class PublishedDiagramStore extends ReactTableStore<StartedDiagramDto> {
    @observable isOpen: boolean = false;
    @observable modal: PublishedDiagramModalStore;

    constructor(private readonly root: RootStore) {
        super();
        this.pageSize = 5;
        this.modal = new PublishedDiagramModalStore(root);
    }

    @action
    async open(): Promise<void> {
        this.root.formsStore.closeModal();
        await this.root.formsStore.publishedDiagrams.close();
        await this.refresh();
        this.isOpen = true;
    }

    @action
    async close(): Promise<void> {
        this.isOpen = false;
    }

    @action
    async download(diagram: StartedDiagramDto, file: StartedDiagramFileNameDto): Promise<void> {
        const response = await this.root.userRpc.user.getStartedDiagramFileById(diagram.id, file.id);
        if (response) {
            const link = document.createElement("a");
            link.download = response.fileName;
            link.href = "data:application/octet-stream;base64," + response.fileContent;
            link.click();
        } else {
            console.warn(`Unable to find file ${file.fileName}`);
        }
    }

    @action
    async refresh(): Promise<void> {
        const response = await this.root.userRpc.user.getStartedDiagrams(this.skip, this.take);
        this.fillItems(response.items, response.totalCount);
    }
}

export class FormsStore extends ReactTableStore<InnoFormBriefDto> {
    @observable publishedDiagrams: PublishedDiagramStore;
    @observable diagrams: DiagramStore;
    @observable isBusy: boolean = false;
    @observable published: boolean = false;
    @observable root: RootStore;
    @observable form?: InnoFormFullDto;
    @observable formData: any;

    constructor(root: RootStore) {
        super();
        this.root = root;
        this.pageSize = 5;
        this.diagrams = new DiagramStore(root);
        this.publishedDiagrams = new PublishedDiagramStore(root);
    }

    @computed get disableForm(): boolean {
        return this.isBusy || !!this.form?.isPublished;
    }

    @computed get jsonSchema(): object {
        const schema = this.form?.jsonSchema;
        if (!schema) return {};
        const schemaInstance = JSON.parse(schema);
        schemaInstance["id"] = uniqueId();
        return schemaInstance;
    }

    @action
    async togglePublished(published: boolean) {
        this.published = published;
        this.page = 0;
        await this.refresh();
        await this.publishedDiagrams.close();
    }

    @action
    async openModal(id: number) {
        this.form = await this.root.userRpc.user.getById(id);
        this.formData = JSON.parse(this.form.json);
        this.isBusy = false;
    }

    @action closeModal() {
        this.form = undefined;
        this.formData = undefined;
        this.isBusy = false;
    }

    @action
    async saveForm() {
        if (!(this.form?.id && this.formData)) return;

        this.isBusy = true;
        const json = JSON.stringify(this.formData);
        const errors = await this.root.userRpc.user.saveForm(this.form.id, json);
        if (errors.length === 0) {
            this.closeModal();
        } else {
            alert("Unable to save form: " + JSON.stringify(errors));
        }

        await this.refresh();
        this.isBusy = false;
    }

    @action
    async publishForm() {
        if (!(this.form?.id && this.formData)) return;
        this.isBusy = true;
        try {
            const id = this.form.id;
            const json = JSON.stringify(this.formData);
            const errors = await this.root.userRpc.user.saveForm(id, json);
            if (errors.length === 0) {
                const certificateInfo = this.root.certificateSelectionStore;
                const certificate = certificateInfo.certificate!;
                const contractAccountId = this.form.contractAccountId;
                const innoChainObjectId = certificateInfo.value?.innoChainObjectId!;
                const key = await this.root.ruTokenApi.getCertificateKey(certificate);
                const info = await this.root.userRpc.userLogin.convertHexKeyToPem(key);
                const signer = new RuTokenPaktSigner(this.root.ruTokenApi, certificate);
                const timestampInSeconds = Math.floor(Date.now() / 1000);
                const transaction = await encodePaktTransaction(
                    {
                        transaction: {
                            type: PaktTransactionType.CallContract,
                            payload: {
                                method: "answer",
                                path: `contracts/${contractAccountId}`,
                                arguments: {
                                    TokenId: this.form.tokenId,
                                    ActivityId: this.form.activityId,
                                    RequestId: this.form.requestId,
                                    Data: this.formData,
                                },
                                key: {
                                    keyType: "gost_2012_256_pem",
                                    keyData: info.value.pem,
                                },
                                from: innoChainObjectId,
                                timestamp: timestampInSeconds,
                            },
                        },
                        blobs: [],
                    },
                    signer
                );

                const encoded = toBase64(transaction);
                const response = await this.root.userRpc.user.publishForm(id, encoded);
                if (response.success) {
                    this.closeModal();
                    await this.refresh();
                } else {
                    this.root.errorStore.errorMessage = "Unable to publish form: " + JSON.stringify(response.error);
                    await this.root.routerStore.goTo(UserRouteNames.error);
                }
            } else {
                alert("Unable to publish form: " + JSON.stringify(errors));
            }
        } catch (error) {
            console.warn(error);
            await this.root.routerStore.goTo(UserRouteNames.error);
        } finally {
            this.isBusy = false;
        }
    }

    @action
    public async refresh(): Promise<void> {
        const response = await this.root.userRpc.user.getAll(this.skip, this.take, this.published);
        this.fillItems(response.items, response.totalCount);
    }
}
