import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { encodePaktTransaction, PaktTransactionType } from "src/stores/pakt";
import { RuTokenPaktSigner, toBase64 } from "src/stores/RuTokenPaktSigner";
import { UserRouteNames } from "src/routes";
import { StartableDiagramDto } from "src/api";
import { ReactTableStore } from "src/stores/table/ReactTableStore";

function getArrayBuffer(file: File): Promise<ArrayBuffer> {
    if (file.arrayBuffer) return file.arrayBuffer();
    // There is no file.arrayBuffer in Safari.
    return new Promise<ArrayBuffer>((resolve) => {
        let reader = new FileReader();
        // @ts-ignore
        reader.onload = () => resolve(reader.result);
        reader.readAsArrayBuffer(file);
    });
}

export class UploadStore extends ReactTableStore<StartableDiagramDto> {
    @observable alias: string = "";
    @observable file: File | null = null;
    @observable success: boolean = true;
    @observable isBusy: boolean = false;
    @observable uploaded: boolean = false;
    @observable isSuccessModalOpen: boolean = false;

    constructor(private readonly root: RootStore) {
        super();
        this.pageSize = 5;
    }

    @computed get canUpload(): boolean {
        return !!this.file && !!this.alias && !this.isBusy;
    }

    @action
    toggleUploaded(enable: boolean) {
        this.uploaded = enable;
    }

    @action
    async goBack() {
        await this.root.routerStore.goTo(UserRouteNames.forms);
    }

    @action
    async upload(): Promise<void> {
        if (this.file === null) return;
        this.isBusy = true;
        try {
            const arrayBuffer: ArrayBuffer = await getArrayBuffer(this.file);
            const certificateInfo = this.root.certificateSelectionStore;
            const certificate = certificateInfo.certificate!;
            const innoChainObjectId = certificateInfo.value?.innoChainObjectId!;
            const key = await this.root.ruTokenApi.getCertificateKey(certificate);
            const info = await this.root.userRpc.userLogin.convertHexKeyToPem(key);
            const signer = new RuTokenPaktSigner(this.root.ruTokenApi, certificate);
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            const transaction = await encodePaktTransaction(
                {
                    transaction: {
                        type: PaktTransactionType.CallContract,
                        payload: {
                            method: "publish",
                            path: "contracts",
                            arguments: {
                                Alias: this.alias,
                            },
                            key: {
                                keyType: "gost_2012_256_pem",
                                keyData: info.value.pem,
                            },
                            from: innoChainObjectId,
                            timestamp: timestampInSeconds,
                        },
                    },
                    blobs: [arrayBuffer],
                },
                signer
            );

            const encoded = toBase64(transaction);
            const response = await this.root.userRpc.user.uploadContract(this.alias, encoded);
            if (response.success) {
                this.success = true;
                this.uploaded = true;
                this.isSuccessModalOpen = true;
                await this.refresh();
            } else {
                this.root.errorStore.errorMessage = "Unable to publish form: " + JSON.stringify(response.error);
                await this.root.routerStore.goTo(UserRouteNames.error);
            }
        } catch (e) {
            console.warn(e);
        } finally {
            this.alias = "";
            this.file = null;
            this.isBusy = false;
        }
    }

    @action
    async refresh(): Promise<void> {
        const response = await this.root.userRpc.user.getStartableDiagrams("", false, this.skip, this.take);
        this.fillItems(response.items, response.totalCount);
    }
}
