import base64js from "base64-js";
import { IPaktSigner } from "src/stores/pakt";
import { Certificate, RuTokenApi } from "src/stores/RuTokenApi";

export function toBase64(bytes: ArrayBuffer): string {
    const uint8Array = new Uint8Array(bytes);
    return base64js.fromByteArray(uint8Array);
}

export function fromHex(str: string): ArrayBuffer {
    const hexArray = str.split(":").map((hex) => parseInt(hex, 16));
    return new Uint8Array(hexArray);
}

export class RuTokenPaktSigner implements IPaktSigner {
    constructor(private readonly api: RuTokenApi, private readonly certificate: Certificate) {}

    getSignatureSize(): number {
        return 64;
    }

    async sign(data: ArrayBuffer): Promise<ArrayBuffer> {
        const str = toBase64(data);
        const signature = await this.api.sign(str, this.certificate);
        return fromHex(signature);
    }
}
